/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

import './Project.css';
import { t } from '../../localization';
import { Layout, Spin, Alert, Modal, Tag, Divider } from 'antd';

import { useContext } from '../../store';
import { Drawing } from '../Drawing';
import { IProject, IDrawing, IDrawingData } from '../../store/state';
import { Sidebar } from './Sidebar';
import { EqualDrawingData } from '../TSCanvas/helper';
import { versions } from './version';

const { Content, Footer } = Layout;

export function Project(props: any) {
  const { state, task } = useContext();

  useEffect(() => {
    const projectTextID = props.match.params.project_textid;
    fetchProject(projectTextID);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.history.location.pathname]);

  async function fetchProject(projectTextID: string) {
    await task.GetProject(projectTextID);
  }

  const projectLoading = state.isProjectLoading;
  const project: IProject | null = state.project;
  const drawing: IDrawing | null = state.drawing;
  const drawingData: IDrawingData = state.drawingData;
  const lastSavedData: IDrawingData = state.lastSavedData;

  if (projectLoading) return <ProjectLoading />;
  if (!projectLoading && !project) return <ProjectError {...props} />;
  if (project === null || drawing === null) return null;

  const unSaved = !EqualDrawingData(drawingData, lastSavedData);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar project={project} task={task} drawing={drawing} drawingData={drawingData} unSaved={unSaved} />
      <Layout>
        <Content style={{ margin: '0 25px' }}>
          <Drawing project={project} drawing={drawing} drawingData={drawingData} unSaved={unSaved} task={task} />
        </Content>
        <Footer>
          <span>{t('DELEVEOPED_BY_D2P')}</span> <VersionPanel />
        </Footer>
      </Layout>
    </Layout>
  );
}

function ProjectLoading() {
  return (
    <section className='empty-container'>
      <Spin size='large' />
    </section>
  );
}

function ProjectError(props: any) {
  return (
    <section className='empty-container'>
      <Alert message={t('PROJECT_NOT_FOUND')} type='error' showIcon />
      <p className='empty-title'>{t('DOUBLE_CHECK_TEXTID')}</p>
      <p className='empty-body'>
        {t('PROJECT_ID')} : <strong>{props.match.params.project_textid}</strong>
      </p>
    </section>
  );
}

function VersionPanel() {
  const [show, setShow] = useState(false);

  return (
    <>
      <a onClick={() => setShow(true)} className='version-title'>
        {t('VERSION_NUMBER')} {versions[0].number}
      </a>
      <Modal width={'50vw'} title={t('VERSION_NUMBER')} visible={show} onCancel={() => setShow(false)}>
        <section className='versions-container'>
          {versions.map((v, index) => (
            <span key={v.number}>
              <Tag color='blue'>{v.number}</Tag>
              {index === 0 && (
                <Tag color='green' style={{ marginLeft: '3px' }}>
                  {t('CURRENT')}
                </Tag>
              )}
              <span className='version-number'>{new Date(v.date).toLocaleDateString()}</span>
              <section className='version-descriptions'>
                {v.descriptions.map((d, i) => (
                  <p className='version-description' key={i}>
                    {d}
                  </p>
                ))}
              </section>
              <Divider />
            </span>
          ))}
        </section>
      </Modal>
    </>
  );
}
