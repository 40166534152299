export interface IVector {
  x: number;
  y: number;
}

export interface ISpanInfo {
  spanDists?: number[];
  spanStart?: IVector[];
  spanEnd?: IVector[];
  others?: { [key: string]: { dist: number; angle: number } };
}

export interface GridLayerProps {
  width: number;
  height: number;
  gridsize: number;
}

export interface LogProps {
  mousePos: IVector;
  mode: TSMode;
}

export interface INode extends ISpanInfo {
  id: string;
  position: IVector;
  offset: IVector;
  isSelected?: boolean;
  isSupported?: boolean;
}

export interface ISegment extends ISpanInfo {
  id: string;
  start: INode;
  end: INode;
  isSelected?: boolean;
  isSupported?: boolean;
  columnsCount?: number;
  columns?: INode[];
}

export interface IBeam extends ISpanInfo {
  id: string;
  segments: ISegment[];
  isClosed?: boolean;
  isOpening?: boolean;
}

export interface ISelection {
  activate: boolean;
  selecting: boolean;
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export interface IMove {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export enum TSMode {
  Select,
  MoveStart,
  MoveEnd,
  AddColumn,
  AddLineStart,
  AddLineEnd,
  AddPolyline,
  AddRectStart,
  AddRectEnd,
}

export enum SegmentType {
  Edge,
  Wall,
  Columns,
  Various,
}

export enum SelectMode {
  Normal,
  Add,
  Remove,
}

export interface IDrawingResult {
  id: number;
  thickness: number;
  max_displacement: number;
  points: { [key: number]: IResultPoint };
  edges: [number[]];
}

export interface IResultPoint {
  pos: number;
  X: number;
  Y: number;
  disp: number;
}
