import React from 'react';
import { TSMode, INode } from './Interfaces';
import { Rect, Group, Circle } from 'react-konva';
import { KonvaEventObject } from 'konva/types/Node';
import { ITask } from '../../store/task';

export interface ColumnProps {
  column: INode;
  mode: TSMode;
  task: ITask;
}

export const TSColumn: React.FC<ColumnProps> = ({ column, task, mode }) => {
  const size = 20;
  let x = column.position.x + column.offset.x;
  let y = column.position.y + column.offset.y;

  return (
    <Group onClick={onClick}>
      <Rect x={x - size / 2} y={y - size / 2} width={size} height={size} stroke='black' strokeWidth={2} />
      <Rect x={x - size / 2} y={y - size / 2} width={size} height={size} fill={column.isSelected ? 'yellow' : '#919191'} opacity={0.5} />
      <Circle x={x} y={y} radius={size / 2 - 4} fillEnabled={false} stroke='919191' strokeWidth={1} />
    </Group>
  );

  function onClick(e: KonvaEventObject<MouseEvent>) {
    if (mode !== TSMode.Select) return;
    if (e.evt.ctrlKey) {
      task.SelectColumn(column, 'remove');
    } else if (e.evt.shiftKey) {
      task.SelectColumn(column, 'add');
    } else {
      task.SelectColumn(column, 'normal');
    }
  }
};
