import React from 'react';
import { ISegment } from './Interfaces';
import { Group, Circle, Line } from 'react-konva';

export interface ColumnRowProps {
  segment: ISegment;
}

export const TSColumnRow: React.FC<ColumnRowProps> = ({ segment }) => {
  const size = 15;

  if (!segment.columns || segment.columns.length === 0) return null;

  return (
    <Group>
      {segment.columns.map((node, i) => {
        const point = node.position;
        return (
          <Group key={i}>
            <Line points={[point.x - size / 2, point.y - size / 2, point.x + size / 2, point.y + size / 2]} stroke='black' strokeWidth={1} />
            <Line points={[point.x - size / 2, point.y + size / 2, point.x + size / 2, point.y - size / 2]} stroke='black' strokeWidth={1} />
            <Circle
              x={point.x}
              y={point.y}
              radius={size / 2 - 4}
              fill={segment.isSelected ? '#ffa500a6' : '#919191'}
              opacity={0.5}
              stroke='919191'
              strokeWidth={1}
            />
          </Group>
        );
      })}
    </Group>
  );
};
