import React, { useState } from 'react';
import './Dashboard.css';
import { t } from '../../localization';
import { Button, Input, message, Modal, List } from 'antd';
import { API } from '../../store/api';
import { IProject } from '../../store/state';
import { distanceString } from '../../localization/timing';
import { versions } from '../Project/version';
const { Search } = Input;

export function Dashboard(props: any) {
  const [projectID, setProjectID] = useState<string>('');
  const [showNewProject, setShowNewProject] = useState<boolean>(false);
  const [showMyProjects, setShowMyProjects] = useState<boolean>(false);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [email, setEmail] = useState<string>('');

  return (
    <section className='dashboard-container'>
      <div className='logo'>{t('TS3')}</div>
      <div className='version'>v {versions[0].number}</div>
      <div className='buttons'>
        <Button onClick={() => setShowNewProject(true)}>{t('NEW_PROJECT')}</Button>
        <Button onClick={() => setShowMyProjects(true)}>{t('MY_PROJECTS')}</Button>
        <Search
          placeholder={t('PROJECT_ID')}
          onChange={(e) => setProjectID(e.target.value)}
          value={projectID}
          enterButton={<Button>{t('OPEN')}</Button>}
          onSearch={() => gotoProject(projectID)}
        />
        <Modal title={t('YOUR_EMAIL')} visible={showNewProject} onOk={createProject} onCancel={() => setShowNewProject(false)}>
          <div style={{ marginBottom: '10px' }}>{t('PROVIDE_EMAIL_TO_LINK_PROJECT')}</div>
          <Input placeholder={t('EMAIL_PLACEHOLDER')} value={email} onChange={(e) => setEmail(e.target.value)} onPressEnter={createProject} />
        </Modal>
        <Modal title={t('YOUR_EMAIL')} visible={showMyProjects} onOk={getMyProjects} onCancel={() => setShowMyProjects(false)}>
          <div style={{ marginBottom: '10px' }}>{t('PROVIDE_EMAIL_TO_GET_PROJECTS')}</div>
          <Input placeholder={t('EMAIL_PLACEHOLDER')} value={email} onChange={(e) => setEmail(e.target.value)} onPressEnter={getMyProjects} />

          {projects.length > 0 && (
            <List
              bordered
              className='project-list'
              dataSource={projects}
              renderItem={(p) => (
                <List.Item className='project-list-item' onClick={() => gotoProject(p.shortId)}>
                  <strong>{p.shortId}</strong> - [{p.name}] <i>{p.description}</i>
                  <span style={{ float: 'right' }}>{distanceString(p.createdAt.toString())}</span>
                </List.Item>
              )}
            />
          )}
        </Modal>
      </div>
    </section>
  );

  async function createProject() {
    if (!email) {
      message.warn(t('EMAIL_CANNOT_BE_EMPTY'));
      return;
    }
    let response = await API.createProject({ name: t('UNTITLED'), email });
    if (response.data) gotoProject(response.data.shortId);
  }

  async function getMyProjects() {
    if (!email) {
      message.warn(t('EMAIL_CANNOT_BE_EMPTY'));
      return;
    }
    let response = await API.getProjects(email);
    if (response.data) {
      setProjects(response.data);
    }
  }

  function gotoProject(textid: string) {
    if (!textid) {
      message.warn(t('PROJECT_ID_CANNOT_BE_EMPTY'));
      return;
    }
    props.history.push(`/p/${textid}`);
  }
}
