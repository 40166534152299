import React from 'react';
import { TSMode, ISegment, IBeam } from './Interfaces';
import { Group, Line } from 'react-konva';
import { KonvaEventObject } from 'konva/types/Node';
import { TSNode } from './TSNode';
import { ITask } from '../../store/task';
import { TSColumnRow } from './TSColumnRow';

export interface SegmentProps {
  segment: ISegment;
  beam: IBeam;
  mode: TSMode;
  task: ITask;
}

export const TSSegment: React.FC<SegmentProps> = ({ segment, beam, task, mode }) => {
  var hasSegmentSelected = beam.segments.some((s) => s.isSelected);

  let startX = segment.start.position.x + segment.start.offset.x;
  let startY = segment.start.position.y + segment.start.offset.y;

  let endX = segment.end.position.x + segment.end.offset.x;
  let endY = segment.end.position.y + segment.end.offset.y;

  const points = [startX, startY, endX, endY];

  return (
    <Group>
      {segment.isSupported && <Line points={points} stroke={segment.isSelected ? 'yellow' : '#111111'} strokeWidth={16} opacity={0.5} />}
      <Line onClick={onClick} points={points} stroke={segment.isSelected ? 'yellow' : hasSegmentSelected ? '#ffa500a6' : 'black'} strokeWidth={4} />
      <TSNode key={segment.start.id} node={segment.start} segment={segment} task={task} mode={mode} />
      <TSNode key={segment.end.id} node={segment.end} segment={segment} task={task} mode={mode} />
      {segment.columnsCount && <TSColumnRow segment={segment} />}
    </Group>
  );

  function onClick(e: KonvaEventObject<MouseEvent>) {
    if (mode !== TSMode.Select) return;

    let newSegments: ISegment[] = [];
    if (e.evt.ctrlKey) {
      newSegments = beam.segments.map((n) => {
        if (n.id !== segment.id) return n;
        return { ...segment, isSelected: false, start: { ...n.start, isSelected: false }, end: { ...n.end, isSelected: false } };
      });
    } else if (e.evt.shiftKey) {
      newSegments = beam.segments.map((n) => {
        if (n.id !== segment.id) return n;
        return { ...segment, isSelected: true, start: { ...n.start, isSelected: false }, end: { ...n.end, isSelected: false } };
      });
    } else {
      newSegments = beam.segments.map((n) => {
        if (n.id !== segment.id) return { ...n, isSelected: false };
        return { ...segment, isSelected: true, start: { ...n.start, isSelected: false }, end: { ...n.end, isSelected: false } };
      });
    }
    let newBeam = { ...beam, segments: newSegments };
    task.UpdateBeam(newBeam);
  }
};
