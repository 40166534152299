/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Button, Tooltip, Checkbox, Input, Radio, Popover, Divider } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  SaveOutlined,
  DashboardOutlined,
  BarChartOutlined,
  RedoOutlined,
  UndoOutlined,
  DeleteOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { TSMode, IBeam, INode, SegmentType } from './Interfaces';
import { SelectIcon, MoveIcon, PolygonIcon, ColumnIcon, LineIcon, RectIcon } from './icons';
import { IDrawing, IDrawingData } from '../../store/state';
import { ITask } from '../../store/task';
import { CreateBeamFromNodes } from './helper';

export interface ToolbarProps {
  isSnap: boolean;
  setIsSnap: React.Dispatch<React.SetStateAction<boolean>>;
  mode: TSMode;
  setMode: React.Dispatch<React.SetStateAction<TSMode>>;
  activeNodes: INode[] | null;
  setActiveNodes: React.Dispatch<React.SetStateAction<INode[] | null>>;
  saveCanvas: any;
  calculateCanvas: any;
  clearResults: any;
  drawing: IDrawing;
  drawingData: IDrawingData;
  unSaved: boolean;
  task: ITask;
}

export const TSToolbar: React.FC<ToolbarProps> = ({
  isSnap,
  setIsSnap,
  mode,
  setMode,
  activeNodes,
  setActiveNodes,
  saveCanvas,
  calculateCanvas,
  clearResults,
  drawing,
  drawingData,
  unSaved,
  task,
}) => {
  const segType = getSegtype();
  const [columnNum, setColumnNum] = useState<string>('');
  const [lastColumnNum, setLastColumnNum] = useState<number>(3);
  const [showSettings, setShowSettings] = useState<boolean>(false);

  useEffect(() => {
    setColumnNum(getColumnRows());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawingData]);

  const hasResults = Boolean(drawing.result);

  return (
    <section className='toolbar-container'>
      <Tooltip title='Select (Esc)'>
        <Button
          size='small'
          type={mode === TSMode.Select ? 'primary' : 'default'}
          icon={<SelectIcon />}
          onClick={() => setMode(TSMode.Select)}
        ></Button>
      </Tooltip>

      <Tooltip title='Delete (Del)'>
        <Button size='small' type={'default'} icon={<DeleteOutlined />} onClick={task.DeleteSelected}></Button>
      </Tooltip>

      <Tooltip title='Move (M)'>
        <Button
          disabled={hasResults}
          size='small'
          type={mode === TSMode.MoveStart || mode === TSMode.MoveEnd ? 'primary' : 'default'}
          icon={<MoveIcon />}
          onClick={() => setMode(TSMode.MoveStart)}
        ></Button>
      </Tooltip>

      <Tooltip title='Column (C)'>
        <Button
          disabled={hasResults}
          size='small'
          type={mode === TSMode.AddColumn ? 'primary' : 'default'}
          icon={<ColumnIcon />}
          onClick={() => setMode(TSMode.AddColumn)}
        ></Button>
      </Tooltip>

      <Tooltip title='Line (L)'>
        <Button
          disabled={hasResults}
          size='small'
          type={mode === TSMode.AddLineStart || mode === TSMode.AddLineEnd ? 'primary' : 'default'}
          icon={<LineIcon />}
          onClick={() => setMode(TSMode.AddLineStart)}
        ></Button>
      </Tooltip>

      <Tooltip title='Rect (R)'>
        <Button
          disabled={hasResults}
          size='small'
          type={mode === TSMode.AddRectStart || mode === TSMode.AddRectEnd ? 'primary' : 'default'}
          icon={<RectIcon />}
          onClick={() => setMode(TSMode.AddRectStart)}
        ></Button>
      </Tooltip>

      <Tooltip title='Polygon (P)'>
        <Button
          disabled={hasResults}
          size='small'
          type={mode === TSMode.AddPolyline ? 'primary' : 'default'}
          icon={<PolygonIcon />}
          onClick={() => setMode(TSMode.AddPolyline)}
        ></Button>
      </Tooltip>

      <Tooltip title='Undo (Ctrl+Z)'>
        <Button disabled={hasResults} size='small' type={'default'} icon={<UndoOutlined />} onClick={task.Undo}></Button>
      </Tooltip>

      <Tooltip title='Redo (Ctrl+Y)'>
        <Button disabled={hasResults} size='small' type={'default'} icon={<RedoOutlined />} onClick={task.Redo}></Button>
      </Tooltip>

      <Tooltip title='Calculate (Ctrl+K)'>
        <Button style={{ float: 'right' }} size='small' type='primary' icon={<DashboardOutlined />} onClick={calculateCanvas}></Button>
      </Tooltip>

      <Tooltip title='Save (Ctrl+S)'>
        <Button style={{ float: 'right' }} size='small' type={unSaved ? 'danger' : 'primary'} icon={<SaveOutlined />} onClick={saveCanvas}></Button>
      </Tooltip>

      <Tooltip title='Settings'>
        <Popover
          placement='bottomRight'
          content={
            <section style={{ display: 'flex', flexDirection: 'column', width: '300' }}>
              <Checkbox checked={isSnap} onChange={(e) => setIsSnap(e.target.checked)}>
                Snap
              </Checkbox>

              <Input
                addonBefore='GridUnit'
                suffix='mm'
                type='number'
                style={{ width: '180px', marginTop: '10px' }}
                size='small'
                value={drawingData.gridUnit}
                onChange={(e) => task.SetGridUnit(parseInt(e.target.value) || 500)}
              ></Input>

              <Divider style={{ margin: '10px 0 4px 0' }} />
              <a onClick={() => setShowSettings(false)}>Close</a>
            </section>
          }
          trigger='click'
          visible={showSettings}
        >
          <Button style={{ float: 'right' }} size='small' type='default' icon={<SettingOutlined />} onClick={() => setShowSettings(true)}></Button>
        </Popover>
      </Tooltip>

      {hasResults && (
        <Button style={{ float: 'right' }} size='small' type='danger' icon={<BarChartOutlined />} onClick={clearResults}>
          Clear Results
        </Button>
      )}

      {drawingData.beams.flatMap((b) => b.segments).filter((s) => s.isSelected).length > 0 && (
        <>
          <Radio.Group
            style={{ float: 'right', color: 'white', marginRight: '10px' }}
            value={segType}
            buttonStyle={segType === SegmentType.Various ? 'outline' : 'solid'}
            size='small'
          >
            <Radio.Button onClick={() => task.changeSupportState(false)} value={SegmentType.Edge}>
              Edge
            </Radio.Button>
            <Radio.Button onClick={() => task.changeSupportState(true)} value={SegmentType.Wall}>
              Wall
            </Radio.Button>
            <Radio.Button onClick={() => task.changeColumnNumber(lastColumnNum)} value={SegmentType.Columns}>
              Columns
            </Radio.Button>
            <Radio.Button style={{ cursor: 'not-allowed' }} value={SegmentType.Various}>
              Various
            </Radio.Button>
          </Radio.Group>
          {segType === SegmentType.Columns && (
            <Input
              addonBefore='Column Count'
              style={{ float: 'right', color: 'white', width: '180px', marginRight: '10px' }}
              size='small'
              value={columnNum}
              onChange={setColumnNumber}
            ></Input>
          )}
        </>
      )}

      {mode === TSMode.AddPolyline && (
        <div style={{ float: 'right' }}>
          <Button size='small' icon={<CheckOutlined />} onClick={confirmAddPolyline} type='danger'>
            Finish
          </Button>
          <Button size='small' icon={<CloseOutlined />} onClick={cancelAddPolyline} type='default'>
            Cancel
          </Button>
        </div>
      )}
    </section>
  );

  function confirmAddPolyline() {
    if (!activeNodes) {
      setMode(TSMode.Select);
      return;
    }
    let newBeam: IBeam = CreateBeamFromNodes(activeNodes, false);
    task.AddBeam(newBeam);
    setActiveNodes(null);
  }

  function cancelAddPolyline() {
    setActiveNodes(null);
    setMode(TSMode.Select);
  }

  function getSegtype(): SegmentType {
    const selected = drawingData.beams.flatMap((b) => b.segments).filter((s) => s.isSelected);
    const supported = selected.filter((s) => s.isSupported);
    const columns = selected.filter((s) => s.columnsCount);
    const edges = selected.filter((s) => !s.isSupported && !s.columnsCount);

    if (edges.length === selected.length) return SegmentType.Edge;
    if (supported.length === selected.length) return SegmentType.Wall;
    if (columns.length === selected.length) return SegmentType.Columns;
    return SegmentType.Various;
  }

  function getColumnRows(): string {
    const selected = drawingData.beams.flatMap((b) => b.segments).filter((s) => s.isSelected);
    const columnRows: number[] = [];
    selected.forEach((seg) => {
      if (seg.columnsCount) columnRows.push(seg.columnsCount);
    });
    if (columnRows.length === 0) return '';
    if (columnRows.length !== selected.length) return 'var';
    const columnsSet = new Set(columnRows);
    if (columnsSet.size > 1) return 'var';
    return columnRows[0].toString();
  }

  function setColumnNumber(e: React.ChangeEvent<HTMLInputElement>) {
    setColumnNum(e.target.value);
    let parsed = parseInt(e.target.value);
    if (isNaN(parsed)) return;
    if (parsed < 3) return;
    setLastColumnNum(parsed);
    task.changeColumnNumber(parsed);
  }
};
