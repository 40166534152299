import { INode, IBeam } from '../Components/TSCanvas/Interfaces';

export const defaultDrawingData: IDrawingData = {
  gridSize: 50,
  gridUnit: 500,
  columns: [],
  beams: [],
};

export interface IState {
  isProjectLoading: boolean;
  project: IProject | null;
  drawing: IDrawing | null;
  drawingData: IDrawingData;
  lastSavedData: IDrawingData;
  dataQueue: IDrawingData[];
  undoIndex: number;
}

export interface IUser {
  id: number;
  email: string;
  firstname: string;
  lastname: string;
  admin: boolean;
  token?: string;
}

export interface IDrawing {
  id: number;
  name: string;
  createdAt: Date;
  serializedData: string;
  result: string;
  projectShortId: string;
}

export interface IProject {
  id: number;
  shortId: string;
  name: string;
  description: string;
  createdAt: Date;
  creator: IUser;
  drawings: IDrawing[];
}

export interface IDrawingData {
  columns: INode[];
  beams: IBeam[];
  gridSize: number;
  gridUnit: number;
}

export const initialState: IState = {
  isProjectLoading: false,
  project: null,
  drawing: null,
  drawingData: defaultDrawingData,
  lastSavedData: defaultDrawingData,
  dataQueue: [defaultDrawingData],
  undoIndex: 0,
};
