import { INode, ISelection, IVector, IBeam, ISegment } from './Interfaces';
import { IDrawingData } from '../../store/state';

export function GenerateID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function NodeInSelection(node: INode, selection: ISelection): boolean {
  let nodeX = node.position.x;
  let nodeY = node.position.y;

  let xRange = [selection.x1, selection.x2].sort((a, b) => a - b);
  let yRange = [selection.y1, selection.y2].sort((a, b) => a - b);

  return nodeX >= xRange[0] && nodeX <= xRange[1] && nodeY >= yRange[0] && nodeY <= yRange[1];
}

export function NodeInNodes(node: INode, nodes: INode[]): boolean {
  for (let n of nodes) {
    if (EqualNode(node, n)) return true;
  }
  return false;
}

export function PointInPoints(pt: IVector, points: IVector[]): boolean {
  for (let p of points) {
    if (EqualPoint(pt, p)) return true;
  }
  return false;
}

export function EqualNode(node1: INode, node2: INode): boolean {
  return EqualPoint(node1.position, node2.position);
}

export function EqualPoint(pt1: IVector, pt2: IVector) {
  return pt1.x === pt2.x && pt1.y === pt2.y;
}

export function CreateBeamFromNodes(nodes: INode[], isClosed: boolean): IBeam {
  var segments: ISegment[] = [];
  for (let i = 0; i < nodes.length - 1; i++) {
    let seg: ISegment = { id: GenerateID(), start: nodes[i], end: nodes[i + 1] };
    segments.push(seg);
  }
  if (isClosed) {
    let seg: ISegment = { id: GenerateID(), start: nodes[nodes.length - 1], end: nodes[0] };
    segments.push(seg);
  }
  let beam: IBeam = { id: GenerateID(), segments, isClosed };
  return beam;
}

export function PolylineInSelection(polyline: IBeam, selection: ISelection): boolean {
  if (polyline.segments.length === 0) return false;
  for (let i = 0; i < polyline.segments.length - 1; i++) {
    if (LineInSelection(polyline.segments[i].start, polyline.segments[i].end, selection)) return true;
  }
  return false;
}

export function LineInSelection(nodeStart: INode, nodeEnd: INode, selection: ISelection): boolean {
  let vx = nodeEnd.position.x - nodeStart.position.x;
  let vy = nodeEnd.position.y - nodeStart.position.y;
  let xRange = [selection.x1, selection.x2].sort((a, b) => a - b);
  let yRange = [selection.y1, selection.y2].sort((a, b) => a - b);
  let left = xRange[0];
  let right = xRange[1];
  let top = yRange[0];
  let bottom = yRange[1];
  let x = nodeStart.position.x;
  let y = nodeStart.position.y;

  var p = [-vx, vx, -vy, vy];
  var q = [x - left, right - x, y - top, bottom - y];
  var u1 = Number.NEGATIVE_INFINITY;
  var u2 = Number.POSITIVE_INFINITY;

  // if one end of the line is in the rectangle, return true
  if (NodeInSelection(nodeStart, selection)) return true;
  if (NodeInSelection(nodeEnd, selection)) return true;

  for (let i of [0, 1, 2, 3]) {
    if (p[i] === 0) {
      if (q[i] < 0) return false;
    } else {
      var t = q[i] / p[i];
      if (p[i] < 0 && u1 < t) u1 = t;
      else if (p[i] > 0 && u2 > t) u2 = t;
    }
  }

  if (u1 > u2 || u1 > 1 || u1 < 0) return false;

  // collision.x = x + u1*vx;
  // collision.y = y + u1*vy;

  return true;
}

export function Distance(position1: IVector, position2: IVector) {
  return Math.sqrt(Math.pow(position1.x - position2.x, 2) + Math.pow(position1.y - position2.y, 2));
}

export function FindClosestNode(position: IVector, columns: INode[]) {
  if (columns.length === 0) return undefined;
  return columns.sort((a, b) => Distance(position, a.position) - Distance(position, b.position))[0];
}

export function PolygonArea(polygon: IBeam) {
  const vertices = GetPointsFromBeam(polygon);

  var total = 0;

  for (var i = 0, l = vertices.length; i < l; i++) {
    var addX = vertices[i].x;
    var addY = vertices[i === vertices.length - 1 ? 0 : i + 1].y;
    var subX = vertices[i === vertices.length - 1 ? 0 : i + 1].x;
    var subY = vertices[i].y;

    total += addX * addY * 0.5;
    total -= subX * subY * 0.5;
  }

  return Math.abs(total);
}

export function GetPointsFromBeam(beam: IBeam): IVector[] {
  var points: IVector[] = [];
  beam.segments.forEach((seg) => points.push(seg.start.position));
  if (!beam.isClosed) points.push(beam.segments[beam.segments.length - 1].end.position);
  return points;
}

export function PolygonInPolygon(polygon1: IBeam, polygon2: IBeam) {
  const points1 = GetPointsFromBeam(polygon1);
  const points2 = GetPointsFromBeam(polygon2);
  for (let p of points1) {
    if (!PointInsidePolygon(p, points2)) return false;
  }
  return true;
}

function PointInsidePolygon(point: IVector, polygon: IVector[]): boolean {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

  var x = point.x,
    y = point.y;

  var inside = false;
  for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    var xi = polygon[i].x,
      yi = polygon[i].y;
    var xj = polygon[j].x,
      yj = polygon[j].y;

    // eslint-disable-next-line no-mixed-operators
    var intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
}

export function SetBeamOpenings(beams: IBeam[]): IBeam[] {
  // sort beams from big to small
  const newBeams = beams.sort((a, b) => PolygonArea(b) - PolygonArea(a)).map((b) => ({ ...b, isOpening: false }));
  const count = newBeams.length;
  if (count < 2) return newBeams;
  for (let i = 0; i < count - 1; i++) {
    for (let j = i + 1; j < count; j++) {
      let bigBeam = newBeams[i];
      let smallBeam = newBeams[j];
      if (bigBeam.isOpening || smallBeam.isOpening) continue;
      if (PolygonInPolygon(smallBeam, bigBeam)) smallBeam.isOpening = true;
    }
  }
  return newBeams;
}

export function MoveBeamSegments(beam: IBeam, offset: IVector) {
  const length = beam.segments.length;
  let offsetStart: { [id: string]: IVector } = {};
  let offsetEnd: { [id: string]: IVector } = {};
  for (let i = 0; i < length; i++) {
    let current = beam.segments[i];
    let previous = null;
    let next = null;
    if (i === 0) {
      previous = beam.isClosed ? beam.segments[length - 1] : null;
      next = beam.segments[i + 1];
    } else if (i === length - 1) {
      previous = beam.segments[i - 1];
      next = beam.isClosed ? beam.segments[0] : null;
    } else {
      previous = beam.segments[i - 1];
      next = beam.segments[i + 1];
    }
    if (!current.isSelected) {
      if (current.start.isSelected) {
        if (previous) offsetEnd[previous.id] = offset;
        offsetStart[current.id] = offset;
      }
      if (current.end.isSelected) {
        offsetEnd[current.id] = offset;
        if (next) offsetStart[next.id] = offset;
      }
    } else {
      offsetStart[current.id] = offset;
      offsetEnd[current.id] = offset;
      if (previous && !previous.isSelected) offsetEnd[previous.id] = offset;
      if (next && !next.isSelected) offsetStart[next.id] = offset;
    }
  }
  return { offsetStart, offsetEnd };
}

export function DeleteBeamSegments(beams: IBeam[]): IBeam[] {
  var newBeams: IBeam[] = [];
  for (let beam of beams) {
    let hasDeletedSegments = beam.segments.some((s) => s.isSelected);
    if (!hasDeletedSegments) {
      newBeams.push(beam);
      continue;
    }
    let newBeam: IBeam = { id: GenerateID(), segments: [] };
    for (let i = 0; i < beam.segments.length; i++) {
      let segment = beam.segments[i];
      if (!segment.isSelected) newBeam.segments.push(segment);
      else if (newBeam.segments.length > 0) {
        newBeams.push(newBeam);
        newBeam = { id: GenerateID(), segments: [] };
      }
    }
    if (newBeam.segments.length > 0) newBeams.push(newBeam);
  }

  return newBeams;
}

export function CalcColumnsFromColumnRowSegment(segment: ISegment) {
  if (!segment.columnsCount) return;

  segment.columns = [];

  let points: IVector[] = [];
  let startX = segment.start.position.x + segment.start.offset.x;
  let startY = segment.start.position.y + segment.start.offset.y;
  let endX = segment.end.position.x + segment.end.offset.x;
  let endY = segment.end.position.y + segment.end.offset.y;
  let division = segment.columnsCount;

  let deltaX = (endX - startX) / (division - 1);
  let deltaY = (endY - startY) / (division - 1);

  for (let i = 0; i < division; i++) {
    let x = startX + deltaX * i;
    let y = startY + deltaY * i;
    points.push({ x, y });
  }

  points.forEach((p) => segment.columns?.push({ id: GenerateID(), isSupported: true, position: p, offset: { x: 0, y: 0 } }));
}

export function ComposeXml(drawingData: IDrawingData): XMLDocument {
  const { columns, beams, gridSize, gridUnit } = drawingData;
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString('<Model></Model>', 'text/xml');
  const modelDom = xmlDoc.firstChild;
  if (modelDom === null) return xmlDoc;

  const createPoint = (v: IVector, tag: string = 'PointModel') => {
    const e = xmlDoc.createElement(tag);

    e.setAttribute('X', ((v.x / gridSize / 1000) * gridUnit).toFixed(3));
    e.setAttribute('Y', ((v.y / gridSize / 1000) * gridUnit).toFixed(3));

    return e;
  };

  // add nodal support
  var supportNodes: IVector[] = [];
  columns.filter((n) => n.isSupported).forEach((node) => supportNodes.push(node.position));

  // add nodal support from colum row
  beams.forEach((beam) => {
    beam.segments.forEach((segment) => {
      if (segment.columns) {
        for (let node of segment.columns) if (!PointInPoints(node.position, supportNodes)) supportNodes.push(node.position);
      }
    });
  });
  supportNodes.forEach((pos) => modelDom.appendChild(createPoint(pos, 'NodalSupportModel')));

  // add line support
  beams
    .flatMap((b) => b.segments)
    .filter((seg) => seg.isSupported)
    .forEach((seg) => {
      const e = xmlDoc.createElement('LineSupportModel');
      e.append(createPoint(seg.start.position));
      e.append(createPoint(seg.end.position));
      modelDom.appendChild(e);
    });

  // add surface
  beams
    .filter((b) => b.isClosed && !b.isOpening)
    .forEach((beam) => {
      const e = xmlDoc.createElement('SurfaceModel');
      beam.segments.forEach((seg) => e.append(createPoint(seg.start.position)));
      modelDom.appendChild(e);
    });

  // add opening
  beams
    .filter((b) => b.isClosed && b.isOpening)
    .forEach((beam) => {
      const e = xmlDoc.createElement('OpeningModel');
      beam.segments.forEach((seg) => e.append(createPoint(seg.start.position)));
      modelDom.appendChild(e);
    });

  return xmlDoc;
}

export function EqualDrawingData(data1: IDrawingData, data2: IDrawingData): boolean {
  return JSON.stringify(data1) === JSON.stringify(data2);
}

enum NodeDirection {
  N = 'N',
  W = 'W',
  S = 'S',
  E = 'E',
}

// a helper function to calculate distance and angle between two points
export function getDistAndAngleFrom2Points(vec1: IVector, vec2: IVector) {
  const dist = Math.hypot(vec1.x - vec2.x, vec1.y - vec2.y);
  // angle is from 0 to 360 counterclockwise
  let angle = (Math.atan2(vec2.y - vec1.y, vec2.x - vec1.x) * 180) / Math.PI;
  if (angle < 0) angle = -angle;
  else if (angle > 0) angle = 360 - angle;
  return { dist, angle };
}

// calculate columns span and store the result
export function GetSpansOfColumns(columns: INode[]) {
  // calculate distance and angle between columns
  columns.forEach((c) => {
    c.spanDists = new Array(4);
    c.spanStart = new Array(4);
    c.spanEnd = new Array(4);
    c.others = {};
  });

  const length = columns.length;
  if (length <= 1) return;

  for (let i = 0; i < length - 1; i++) {
    for (let j = i + 1; j < length; j++) {
      const nodeA = columns[i];
      const nodeB = columns[j];
      const { dist, angle } = getDistAndAngleFrom2Points(nodeA.position, nodeB.position);
      if (
        !nodeA.spanDists ||
        !nodeA.spanStart ||
        !nodeA.spanEnd ||
        !nodeA.others ||
        !nodeB.spanDists ||
        !nodeB.spanStart ||
        !nodeB.spanEnd ||
        !nodeB.others
      )
        continue;

      // put this info to each node
      nodeA.others[nodeB.id] = { dist, angle };
      nodeB.others[nodeA.id] = { dist, angle: (angle + 180) % 360 };
      // compare with existing span
      const dirA = getDirectionFromAngle(angle);
      let indexA = 0;
      if (dirA === NodeDirection.N) indexA = 0;
      else if (dirA === NodeDirection.W) indexA = 1;
      else if (dirA === NodeDirection.S) indexA = 2;
      else if (dirA === NodeDirection.E) indexA = 3;

      if (!nodeA.spanDists[indexA] || nodeA.spanDists[indexA] > dist) {
        nodeA.spanDists[indexA] = dist;
        nodeA.spanStart[indexA] = nodeA.position;
        nodeA.spanEnd[indexA] = nodeB.position;
      }

      let indexB = (indexA + 2) % 4;

      if (!nodeB.spanDists[indexB] || nodeB.spanDists[indexB] > dist) {
        nodeB.spanDists[indexB] = dist;
        nodeB.spanStart[indexB] = nodeB.position;
        nodeB.spanEnd[indexB] = nodeA.position;
      }
    }
  }
}

// calculate walls span and store the result
export function GetSpansOfWalls(walls: IBeam[]) {
  for (let wall of walls) {
    wall.spanDists = new Array(4);
    wall.spanStart = new Array(4);
    wall.spanEnd = new Array(4);
    wall.others = {};

    wall.segments.forEach((s) => {
      s.spanDists = new Array(4);
      s.spanStart = new Array(4);
      s.spanEnd = new Array(4);
      s.others = {};
    });
  }

  const length = walls.length;
  if (length <= 1) return;

  for (let i = 0; i < length - 1; i++) {
    for (let j = i + 1; j < length; j++) {
      const wallA = walls[i];
      const wallB = walls[j];

      // calculate distance and angle between walls
      const [start, end] = getWallClosestLine(wallA, wallB);
      if (!start || !end) continue;
      const { dist, angle } = getDistAndAngleFrom2Points(start, end);
      if (
        !wallA.spanDists ||
        !wallA.spanStart ||
        !wallA.spanEnd ||
        !wallA.others ||
        !wallB.spanDists ||
        !wallB.spanStart ||
        !wallB.spanEnd ||
        !wallB.others
      )
        continue;

      // put this info to each wall
      wallA.others[wallB.id] = { dist, angle };
      wallB.others[wallA.id] = { dist, angle: (angle + 180) % 360 };
      // compare with existing span
      const dirA = getDirectionFromAngle(angle);
      let indexA = 0;
      if (dirA === NodeDirection.N) indexA = 0;
      else if (dirA === NodeDirection.W) indexA = 1;
      else if (dirA === NodeDirection.S) indexA = 2;
      else if (dirA === NodeDirection.E) indexA = 3;

      if (!wallA.spanDists[indexA] || wallA.spanDists[indexA] > dist) {
        wallA.spanDists[indexA] = dist;
        wallA.spanStart[indexA] = start;
        wallA.spanEnd[indexA] = end;
      }

      let indexB = (indexA + 2) % 4;

      if (!wallB.spanDists[indexB] || wallB.spanDists[indexB] > dist) {
        wallB.spanDists[indexB] = dist;
        wallB.spanStart[indexB] = end;
        wallB.spanEnd[indexB] = start;
      }
    }
  }
}

// calculate column and segment span and store the result
export function GetSpanOfColumnsAndWalls(nodes: INode[], walls: IBeam[]) {
  for (let wall of walls) {
    for (let segment of wall.segments) {
      if (!segment.spanDists || !segment.spanStart || !segment.spanEnd || !segment.others) continue;

      if (!segment.isSupported) continue;

      for (let node of nodes) {
        const closestPt = getSegmentClosestPoint(node, segment);
        const { dist, angle } = getDistAndAngleFrom2Points(node.position, closestPt);

        if (!node.others || !node.spanDists || !node.spanStart || !node.spanEnd) continue;

        // put this info to each node
        node.others[segment.id] = { dist, angle };
        segment.others[node.id] = { dist, angle: (angle + 180) % 360 };

        // compare with existing span
        const dirA = getDirectionFromAngle(angle);
        let indexA = 0;
        if (dirA === NodeDirection.N) indexA = 0;
        else if (dirA === NodeDirection.W) indexA = 1;
        else if (dirA === NodeDirection.S) indexA = 2;
        else if (dirA === NodeDirection.E) indexA = 3;

        if (!node.spanDists[indexA] || node.spanDists[indexA] > dist) {
          node.spanDists[indexA] = dist;
          node.spanStart[indexA] = node.position;
          node.spanEnd[indexA] = closestPt;
        }

        let indexB = (indexA + 2) % 4;

        if (!segment.spanDists[indexB] || segment.spanDists[indexB] > dist) {
          segment.spanDists[indexB] = dist;
          segment.spanStart[indexB] = closestPt;
          segment.spanEnd[indexB] = node.position;
        }
      }
    }
  }
}

// get maximum span for a node or a segment
export function GetNodeMaximumSpan(item: INode | ISegment | IBeam) {
  if (!item.spanDists || !item.spanStart || !item.spanEnd) return;
  let maxSpan = 0;
  let spanStart = undefined;
  let spanEnd = undefined;
  for (let i = 0; i < 4; i++) {
    if (item.spanDists[i] && item.spanDists[i] > maxSpan) {
      maxSpan = item.spanDists[i];
      spanStart = item.spanStart[i];
      spanEnd = item.spanEnd[i];
    }
  }
  if (spanStart && spanEnd) {
    return { spanStart, span: maxSpan, spanEnd };
  } else return undefined;
}

export function GetMaximumSpan(columns: INode[], beams: IBeam[]) {
  let spanInfos = [];
  for (let node of columns) {
    const spanInfo = GetNodeMaximumSpan(node);
    if (!spanInfo) continue;
    spanInfos.push(spanInfo);
  }
  for (let wall of beams) {
    const spanInfo = GetNodeMaximumSpan(wall);
    if (spanInfo) spanInfos.push(spanInfo);

    // for (let segment of wall.segments) {
    //   const spanInfo = GetNodeMaximumSpan(segment);
    //   if (!spanInfo) continue;
    //   spanInfos.push(spanInfo);
    // }
  }
  if (spanInfos.length === 0) return undefined;
  const maxSpan = spanInfos.sort((a, b) => b.span - a.span)[0];
  // const maxSpans = spanInfos.filter((s) => s.span === maxSpan.span);
  // if (!maxSpans) return undefined;
  return [maxSpan];
}

// check direction from 0-360 angle and distribute into four zones
function getDirectionFromAngle(angle: number) {
  if (angle >= 45 && angle < 135) return NodeDirection.N;
  if (angle >= 135 && angle < 225) return NodeDirection.W;
  if (angle >= 225 && angle < 315) return NodeDirection.S;
  if (angle >= 315 || angle < 45) return NodeDirection.E;
}

// find a closest point on a segment for a point
function getSegmentClosestPoint(node: INode, segment: ISegment) {
  let a = segment.start.position;
  let b = segment.end.position;
  let p = node.position;
  var atob = { x: b.x - a.x, y: b.y - a.y };
  var atop = { x: p.x - a.x, y: p.y - a.y };
  var len = atob.x * atob.x + atob.y * atob.y;
  var dot = atop.x * atob.x + atop.y * atob.y;
  var t = Math.min(1, Math.max(0, dot / len));

  let point: IVector = { x: a.x + atob.x * t, y: a.y + atob.y * t };

  return point;
}

// find a two closes points on a segment for a segment
function getWallClosestLine(wallA: IBeam, wallB: IBeam) {
  function addNodes(node: INode, nodes: INode[]) {
    if (!NodeInNodes(node, nodes)) nodes.push(node);
  }
  function getSupportNodesFromWall(wall: IBeam) {
    let nodes: INode[] = [];
    for (let segment of wall.segments) {
      if (!segment.isSupported) continue;
      addNodes(segment.start, nodes);
      addNodes(segment.end, nodes);
    }
    return nodes;
  }

  const nodesA = getSupportNodesFromWall(wallA);
  const nodesB = getSupportNodesFromWall(wallB);

  let minDist;
  let minStart;
  let minEnd;

  for (let node of nodesA) {
    for (let segment of wallB.segments) {
      if (!segment.isSupported) continue;
      const closestPt = getSegmentClosestPoint(node, segment);
      const { dist } = getDistAndAngleFrom2Points(node.position, closestPt);
      if (!minDist || dist < minDist) {
        minDist = dist;
        minStart = node.position;
        minEnd = closestPt;
      }
    }
  }

  for (let node of nodesB) {
    for (let segment of wallA.segments) {
      if (!segment.isSupported) continue;
      const closestPt = getSegmentClosestPoint(node, segment);
      const { dist } = getDistAndAngleFrom2Points(node.position, closestPt);
      if (!minDist || dist < minDist) {
        minDist = dist;
        minStart = node.position;
        minEnd = closestPt;
      }
    }
  }

  return [minStart, minEnd];
}
