import React from 'react';
import { LogProps, TSMode } from './Interfaces';

const TSLog: React.FC<LogProps> = ({ mousePos, mode }) => {
  const message = getModeMessage();

  return (
    <section className='log-container'>
      <span>{`x:${mousePos.x.toFixed(0)}, y:${mousePos.y.toFixed(0)} | ${message}`}</span>
    </section>
  );

  function getModeMessage() {
    if (mode === TSMode.Select) return 'Click to select. Ctrl+Click to remove from selection. Shift+Click to add to selection';
    if (mode === TSMode.AddColumn) return 'Place a column...';
    if (mode === TSMode.AddPolyline) return 'Place the node of the polygon...';
    if (mode === TSMode.MoveStart) return 'Pick the start point to move...(Please select geometries to move first.)';
    if (mode === TSMode.MoveEnd) return 'Pick the end point.';
    if (mode === TSMode.AddLineStart) return 'Pick the start point of the line.';
    if (mode === TSMode.AddLineEnd) return 'Pick the end point of the line.';
    if (mode === TSMode.AddRectStart) return 'Pick the first corner of rectangle.';
    if (mode === TSMode.AddRectEnd) return 'Pick the other corner of rectangle.';
  }
};

export default TSLog;
