import React, { useState, useEffect } from 'react';
import { TSToolbar } from './TSToolbar';
import TSLog from './TSLog';
import TSWorkspace from './TSWorkspace';
import { IVector, TSMode, ISelection, IMove, INode } from './Interfaces';
import { message, Spin, Modal } from 'antd';
import { t } from '../../localization';
import { ComposeXml } from './helper';
import { IDrawing, IDrawingData } from '../../store/state';
import { ITask } from '../../store/task';

export interface CanvasProps {
  width: number;
  height: number;
  drawing: IDrawing;
  drawingData: IDrawingData;
  unSaved: boolean;
  task: ITask;
}

const TSCanvas: React.FC<CanvasProps> = ({ width, height, drawing, drawingData, unSaved, task }) => {
  // mouse pointer
  const [mousePos, setMousePos] = useState<IVector>({ x: 0, y: 0 });
  const [isSnap, setIsSnap] = useState<boolean>(true);

  // move
  const [move, setMove] = useState<IMove>({ x1: 0, x2: 0, y1: 0, y2: 0 });

  // state
  const [mode, setMode] = useState<TSMode>(TSMode.Select);
  const [selection, setSelection] = useState<ISelection>({ activate: false, selecting: false, x1: 0, x2: 0, y1: 0, y2: 0 });
  const [calculating, setCalculating] = useState<boolean>(false);

  // components
  const [activeNodes, setActiveNodes] = useState<INode[] | null>(null);

  useEffect(() => {
    window.addEventListener('keydown', onkeydown);
    window.addEventListener('beforeunload', onUnload);

    return () => {
      window.removeEventListener('keydown', onkeydown);
      window.removeEventListener('beforeunload', onUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawingData]);

  return (
    <Spin spinning={calculating} tip={t('CALCULATING')}>
      <section className='canvas-container' style={{ width: `${width}px`, height: `${height + 72}px` }}>
        <TSToolbar
          isSnap={isSnap}
          setIsSnap={setIsSnap}
          mode={mode}
          setMode={setMode}
          activeNodes={activeNodes}
          setActiveNodes={setActiveNodes}
          saveCanvas={saveCanvas}
          calculateCanvas={calculateCanvas}
          clearResults={clearResults}
          drawing={drawing}
          drawingData={drawingData}
          unSaved={unSaved}
          task={task}
        />
        <TSWorkspace
          width={width}
          height={height}
          mousePos={mousePos}
          setMousePos={setMousePos}
          isSnap={isSnap}
          mode={mode}
          setMode={setMode}
          selection={selection}
          setSelection={setSelection}
          activeNodes={activeNodes}
          setActiveNodes={setActiveNodes}
          move={move}
          setMove={setMove}
          drawing={drawing}
          drawingData={drawingData}
          task={task}
        />
        <TSLog mousePos={mousePos} mode={mode} />
      </section>
    </Spin>
  );

  function SaveConfirm() {
    Modal.confirm({
      title: 'Drawing Unsaved',
      icon: null,
      content: 'The drawing has unsaved content. Do you want to save it?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => onConfirm(true),
      onCancel: () => onConfirm(false),
    });
  }

  async function onConfirm(save: boolean) {
    if (save) await saveCanvas();
  }

  function onUnload(e: BeforeUnloadEvent) {
    if (unSaved) {
      e.preventDefault();
      SaveConfirm();
      e.returnValue = '';
    }
  }

  function onkeydown(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      Escape();
    } else if (e.key === 'm') {
      setMode(TSMode.MoveStart);
    } else if (e.key === 'Delete') {
      if (mode !== TSMode.Select) return;
      task.DeleteSelected();
    } else if (e.key === 'c') {
      setMode(TSMode.AddColumn);
    } else if (e.key === 'r') {
      setMode(TSMode.AddRectStart);
    } else if (e.key === 'p') {
      setMode(TSMode.AddPolyline);
    } else if (e.key === 'l') {
      setMode(TSMode.AddLineStart);
    } else if (e.key === 'z' && e.ctrlKey) {
      task.Undo();
    } else if (e.key === 'y' && e.ctrlKey) {
      task.Redo();
    } else if (e.key === 's' && e.ctrlKey) {
      e.preventDefault();
      saveCanvas();
    } else if (e.key === 'k' && e.ctrlKey) {
      e.preventDefault();
      calculateCanvas();
    }
  }

  function Escape() {
    setMode(TSMode.Select);
    // clear selection and move
    task.UnselectAll();
  }

  async function saveCanvas() {
    let response = await task.UpdateDrawing(drawing.id, { serializedData: JSON.stringify(drawingData) });
    if (response.success) {
      message.success(t('DRAWING_SAVED'));
    }
  }

  async function clearResults() {
    let response = await task.UpdateDrawing(drawing.id, { result: '' });
    if (response.success) {
      message.success(t('RESULTS_CLEARED'));
    }
  }

  async function calculateCanvas() {
    setMode(TSMode.Select);
    await saveCanvas();
    setCalculating(true);
    const xmlContent = ComposeXml(drawingData);
    let response = await task.CalculateDrawing(drawing.id, xmlContent);
    setCalculating(false);
    if (response.success) {
      message.success(t('DRAWING_CALCULATED'));
    } else {
      Modal.error({
        title: t('DRAWING_CALCULATING_FAILED'),
        content: response.message,
      });
    }
  }
};

export default TSCanvas;
