import React, { useState, useEffect } from 'react';
import { Layer, Line, Group, Text, Rect } from 'react-konva';
import { IDrawingResult, IResultPoint } from './Interfaces';
import { IDrawing, IDrawingData } from '../../store/state';

export interface ResultLayerProps {
  drawing: IDrawing;
  drawingData: IDrawingData;
}

const TSResultLayer: React.FC<ResultLayerProps> = ({ drawing, drawingData }) => {
  let resultsData: IDrawingResult[] = JSON.parse(drawing.result).sort((a: IDrawingResult, b: IDrawingResult) => a.thickness - b.thickness);

  const [result, setResult] = useState<IDrawingResult>(resultsData[0]);

  useEffect(() => {
    setResult(resultsData[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawing]);

  if (!result) return null;

  const maxDisp: number = Math.max(...resultsData.map((r) => r.max_displacement));

  return (
    <Layer name='resultlayer'>
      <Group>
        <Line
          points={[10, 10, 40, 10, 40, 690, 10, 690]}
          closed
          fillLinearGradientStartPoint={{ x: 10, y: 10 }}
          fillLinearGradientEndPoint={{ x: 10, y: 690 }}
          fillLinearGradientColorStops={[
            0,
            `hsl(230, 100%,50%)`,
            0.25,
            `hsl(172, 100%,50%)`,
            0.5,
            `hsl(115, 100%,50%)`,
            0.75,
            `hsl(57, 100%,50%)`,
            1,
            `hsl(0, 100%,50%)`,
          ]}
          opacity={0.8}
        />
        <Text text={'0.00'} rotation={-90} x={15} y={45} fill='white' padding={5} fontSize={12} fontStyle='bold' />
        <Text text={'max disp. ' + maxDisp.toFixed(2)} rotation={-90} x={15} y={690} fill='white' padding={5} fontSize={12} fontStyle='bold' />
      </Group>
      {result.edges.map((edge, i) => {
        let points: IResultPoint[] = edge.map((p) => result.points[p]).filter((p) => p);
        points = points.map((p) => ({ ...p, X: toGridPoint(p.X), Y: toGridPoint(p.Y) }));
        let startPt = points[0];
        let endPt = points[points.length - 1];

        let c0 = `hsl(${(1 - startPt.disp / maxDisp) * 230},100%,50%)`;
        let c3 = `hsl(${(1 - endPt.disp / maxDisp) * 230},100%,50%)`;

        return (
          <Line
            key={i}
            points={points.map((p) => [p.X, p.Y]).flat()}
            closed
            fillLinearGradientStartPoint={{ x: startPt.X, y: startPt.Y }}
            fillLinearGradientEndPoint={{ x: endPt.X, y: endPt.Y }}
            fillLinearGradientColorStops={[0, c0, 1, c3]}
            opacity={0.8}
          />
        );
      })}

      <Group>
        {resultsData.map((data, i) => (
          <ResultOption
            key={i}
            index={i}
            result={data}
            selected={JSON.stringify(result) === JSON.stringify(resultsData[i])}
            results={resultsData}
            setResult={setResult}
          />
        ))}
      </Group>
    </Layer>
  );

  function toGridPoint(n: number) {
    return Math.round((n / drawingData.gridUnit) * drawingData.gridSize * 1000);
  }
};

export default TSResultLayer;

interface ResultOptionProps {
  results: IDrawingResult[];
  result: IDrawingResult;
  setResult: React.Dispatch<React.SetStateAction<IDrawingResult>>;
  index: number;
  selected: boolean;
}

const ResultOption: React.FC<ResultOptionProps> = ({ index, result, setResult, selected }) => {
  return (
    <Group onClick={selectResult} x={60} y={10 + index * 34}>
      <Rect width={250} height={26} fill={selected ? 'yellow' : '#ddd'} stroke='#000' cornerRadius={3} />
      <Text padding={9} text={`thickness: ${result.thickness}mm, displacement: ${result.max_displacement}mm`} />
    </Group>
  );

  function selectResult() {
    setResult(result);
  }
};
