/*
  This module handles higher layer of actions.
  It deals with tasks and dispatch actions to reducers.
*/

import { API, INewProjectPayload, IEditProjectPayload, INewDrawingPayload, IEditDrawingPayload } from './api';
import { IDrawing } from './state';
import { IBeam, INode, ISelection, IVector, ISegment } from '../Components/TSCanvas/Interfaces';

interface RespondProps {
  success: boolean;
  message?: string;
}

export interface ITask {
  GetProject: (projectTextID: string) => Promise<RespondProps>;
  CreateProject: (projectPayload: INewProjectPayload) => Promise<RespondProps>;
  UpdateProject: (projectTextID: string, projectPayload: IEditProjectPayload) => Promise<RespondProps>;
  DeleteProject: (projectTextID: string) => Promise<RespondProps>;
  CreateDrawing: (drawingPayload: INewDrawingPayload) => Promise<RespondProps>;
  UpdateDrawing: (drawingID: number, drawingPayload: IEditDrawingPayload) => Promise<RespondProps>;
  CalculateDrawing: (drawingID: number, serializedDrawing: XMLDocument) => Promise<RespondProps>;
  DeleteDrawing: (drawingID: number) => Promise<RespondProps>;
  SelectDrawing: (drawing: IDrawing) => void;
  AddBeam: (newBeam: IBeam) => void;
  AddColumn: (newBeam: INode) => void;
  UpdateBeam: (beam: IBeam) => void;
  UpdateSegment: (segment: ISegment) => void;
  DeleteSelected: () => void;
  UnselectAll: () => void;
  StartMove: (offset: IVector) => void;
  EndMove: () => void;
  SelectColumn: (node: INode, mode: string) => void;
  Select: (selection: ISelection, mode: string) => void;
  SetGridUnit: (unit: number) => void;
  changeSupportState: (support: boolean) => void;
  changeColumnNumber: (columnNum: number) => void;
  Undo: () => void;
  Redo: () => void;
}

export function useTask(dispatch: any): ITask {
  // get a project by id
  const GetProject = async (projectTextID: string) => {
    dispatch({ type: 'GET_PROJECT_PENDING' });
    try {
      let response = await API.getProject(projectTextID);
      dispatch({ type: 'GET_PROJECT_FULFILLED', payload: response.data });
      return { success: true };
    } catch (err) {
      dispatch({ type: 'GET_PROJECT_ERROR', payload: err });
      return { success: false, message: err.response.data.message };
    }
  };

  // create a project
  const CreateProject = async (projectPayload: INewProjectPayload) => {
    try {
      let response = await API.createProject(projectPayload);
      dispatch({ type: 'GET_PROJECT_FULFILLED', payload: response.data });
      return { success: true };
    } catch (err) {
      return { success: false, message: err.response.data.message };
    }
  };

  // update a project
  const UpdateProject = async (projectTextID: string, projectPayload: IEditProjectPayload) => {
    try {
      let response = await API.updateProject(projectTextID, projectPayload);
      dispatch({ type: 'UPDATE_PROJECT_FULFILLED', payload: response.data });
      return { success: true };
    } catch (err) {
      return { success: false, message: err.response.data.message };
    }
  };

  // delete a project
  const DeleteProject = async (projectTextID: string) => {
    try {
      await API.deleteProject(projectTextID);
      return { success: true };
    } catch (err) {
      return { success: false, message: err.response.data.message };
    }
  };

  // create a drawing
  const CreateDrawing = async (drawingPayload: INewDrawingPayload) => {
    try {
      let response = await API.createDrawing(drawingPayload);
      dispatch({ type: 'CREATE_DRAWING_FULFILLED', payload: response.data });
      return { success: true };
    } catch (err) {
      return { success: false, message: err.response.data.message };
    }
  };

  // update drawing
  const UpdateDrawing = async (drawingID: number, drawingPayload: IEditDrawingPayload) => {
    try {
      let response = await API.updateDrawing(drawingID, drawingPayload);
      dispatch({ type: 'UPDATE_DRAWING_FULFILLED', payload: response.data });
      return { success: true };
    } catch (err) {
      return { success: false, message: err.response.data.message };
    }
  };

  // calculate drawing
  const CalculateDrawing = async (drawingID: number, serializedDrawing: XMLDocument) => {
    try {
      let response = await API.calculateDrawing(drawingID, serializedDrawing);
      dispatch({ type: 'UPDATE_DRAWING_FULFILLED', payload: response.data });
      return { success: true };
    } catch (err) {
      return { success: false, message: err.response.data.message };
    }
  };

  // delete drawing
  const DeleteDrawing = async (drawingID: number) => {
    try {
      await API.deleteDrawing(drawingID);
      dispatch({ type: 'DELETE_DRAWING_FULFILLED', payload: drawingID });
      return { success: true };
    } catch (err) {
      return { success: false, message: err.response.data.message };
    }
  };

  const SelectDrawing = (drawing: IDrawing) => {
    dispatch({ type: 'SET_ACTIVE_DRAWING', payload: drawing });
  };

  // canvas methods
  const AddBeam = (newBeam: IBeam) => {
    dispatch({ type: 'CREATE_BEAM', payload: newBeam });
  };

  const AddColumn = (newColumn: INode) => {
    dispatch({ type: 'CREATE_COLUMN', payload: newColumn });
  };

  const UpdateBeam = (beam: IBeam) => {
    dispatch({ type: 'UNSELECT_ALL_NODES' });
    dispatch({ type: 'UPDATE_BEAM', payload: beam });
  };

  const UpdateSegment = (segment: ISegment) => {
    dispatch({ type: 'UNSELECT_ALL' });
    dispatch({ type: 'UPDATE_SEGMENT', payload: segment });
  };

  const DeleteSelected = () => {
    dispatch({ type: 'DELETE_SELECTED' });
  };

  const SelectColumn = (node: INode, mode: string = 'normal') => {
    dispatch({ type: 'SELECT_COLUMN', payload: { node, mode } });
  };

  const Select = (selection: ISelection, mode: string = 'normal') => {
    dispatch({ type: 'SELECT', payload: { selection, mode } });
  };

  const UnselectAll = () => {
    dispatch({ type: 'UNSELECT_ALL' });
  };

  const StartMove = (offset: IVector) => {
    dispatch({ type: 'START_MOVE', payload: offset });
  };

  const EndMove = () => {
    dispatch({ type: 'END_MOVE' });
  };

  const SetGridUnit = (unit: number) => {
    dispatch({ type: 'SET_GRID_UNIT', payload: unit });
  };

  const changeSupportState = (support: boolean) => {
    dispatch({ type: 'SET_SUPPORT_STATE', payload: support });
  };

  const changeColumnNumber = (columnNum: number) => {
    dispatch({ type: 'SET_COLUMN_NUMBER', payload: columnNum });
  };

  const Undo = () => {
    dispatch({ type: 'UNDO' });
  };
  const Redo = () => {
    dispatch({ type: 'REDO' });
  };

  return {
    GetProject,
    CreateProject,
    UpdateProject,
    DeleteProject,
    CreateDrawing,
    UpdateDrawing,
    CalculateDrawing,
    DeleteDrawing,
    SelectDrawing,
    AddBeam,
    AddColumn,
    UpdateBeam,
    UpdateSegment,
    DeleteSelected,
    UnselectAll,
    StartMove,
    EndMove,
    SelectColumn,
    Select,
    SetGridUnit,
    changeSupportState,
    changeColumnNumber,
    Undo,
    Redo,
  };
}
