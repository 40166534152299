import React from 'react';
import { Stage } from 'react-konva';
import TSGridLayer from './TSGridLayer';
import { KonvaEventObject } from 'konva/types/Node';
import { TSMode, INode, ISelection, IBeam, IMove, IVector } from './Interfaces';
import TSCompLayer from './TSCompLayer';
import { message } from 'antd';
import { GenerateID, NodeInNodes, EqualNode, CreateBeamFromNodes } from './helper';
import TSResultLayer from './TSResultLayer';
import { IDrawing, IDrawingData } from '../../store/state';
import { ITask } from '../../store/task';
import { TSGhostLayer } from './TSGhostLayer';
import TSSpanLayer from './TSSpanLayer';

export interface WorkspaceProps {
  width: number;
  height: number;
  mousePos: IVector;
  setMousePos: React.Dispatch<React.SetStateAction<IVector>>;
  isSnap: boolean;
  mode: TSMode;
  setMode: React.Dispatch<React.SetStateAction<TSMode>>;
  selection: ISelection;
  setSelection: React.Dispatch<React.SetStateAction<ISelection>>;
  activeNodes: INode[] | null;
  setActiveNodes: React.Dispatch<React.SetStateAction<INode[] | null>>;
  move: IMove;
  setMove: React.Dispatch<React.SetStateAction<IMove>>;
  drawing: IDrawing;
  drawingData: IDrawingData;
  task: ITask;
}

const TSWorkspace: React.FC<WorkspaceProps> = ({
  width,
  height,
  mousePos,
  setMousePos,
  isSnap,
  mode,
  setMode,
  selection,
  setSelection,
  activeNodes,
  setActiveNodes,
  move,
  setMove,
  drawing,
  drawingData,
  task,
}) => {
  return (
    <section className='workspace-container'>
      <Stage width={width} height={height} onClick={onClick} onMouseMove={onMouseMove} onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
        <TSCompLayer drawingData={drawingData} mode={mode} task={task} />
        <TSSpanLayer drawingData={drawingData} mode={mode} task={task} />
        <TSGridLayer width={width} height={height} gridsize={drawingData.gridSize} />
        <TSGhostLayer mousePos={mousePos} mode={mode} selection={selection} activeNodes={activeNodes} move={move} />
        {Boolean(drawing.result) && <TSResultLayer drawing={drawing} drawingData={drawingData} />}
      </Stage>
    </section>
  );

  function onClick(e: KonvaEventObject<MouseEvent>) {
    // deselect all
    if (mode === TSMode.Select) {
      // if (e.target === e.currentTarget) setColumns(columns.map(n => ({ ...n, isSelected: false })));
    }
    // add column mode
    else if (mode === TSMode.AddColumn) {
      let newColumn: INode = { position: mousePos, id: GenerateID(), isSupported: true, offset: { x: 0, y: 0 } };
      if (drawingData.columns.some((n) => n.position.x === mousePos.x && n.position.y === mousePos.y)) {
        message.error('Nodes cannot be added at the same position.');
      }
      task.AddColumn(newColumn);
    }
    // add line mode
    else if (mode === TSMode.AddLineStart) {
      // create new node
      let newNode: INode = { id: GenerateID(), position: mousePos, offset: { x: 0, y: 0 } };
      setActiveNodes([newNode]);
      setMode(TSMode.AddLineEnd);
    } else if (mode === TSMode.AddLineEnd) {
      if (!activeNodes) return;
      let newNode: INode = { id: GenerateID(), position: mousePos, offset: { x: 0, y: 0 } };
      if (EqualNode(newNode, activeNodes[0])) {
        message.warn('The start and end of the line should not overlap.');
        return;
      } else {
        let n1: INode = activeNodes[0];
        let n2: INode = { id: GenerateID(), position: mousePos, offset: { x: 0, y: 0 } };
        let newBeam: IBeam = CreateBeamFromNodes([n1, n2], false);
        task.AddBeam(newBeam);
        setActiveNodes(null);
        setMode(TSMode.AddLineStart);
      }
    }
    // add rect mode
    else if (mode === TSMode.AddRectStart) {
      // create new node
      let newNode: INode = { id: GenerateID(), position: mousePos, offset: { x: 0, y: 0 } };
      setActiveNodes([newNode]);
      setMode(TSMode.AddRectEnd);
    } else if (mode === TSMode.AddRectEnd) {
      if (!activeNodes) return;
      let newNode: INode = { id: GenerateID(), position: mousePos, offset: { x: 0, y: 0 } };
      if (NodeInNodes(newNode, activeNodes)) {
        message.warn('A polyline should not have duplicated node except the end node.');
        return;
      } else {
        let n1: INode = activeNodes[0];
        let n2: INode = { id: GenerateID(), position: { x: mousePos.x, y: n1.position.y }, offset: { x: 0, y: 0 } };
        let n3: INode = { id: GenerateID(), position: mousePos, offset: { x: 0, y: 0 } };
        let n4: INode = { id: GenerateID(), position: { x: n1.position.x, y: mousePos.y }, offset: { x: 0, y: 0 } };
        let newBeam: IBeam = CreateBeamFromNodes([n1, n2, n3, n4], true);
        task.AddBeam(newBeam);
        setActiveNodes(null);
        setMode(TSMode.AddRectStart);
      }
    }
    // add polyline mode
    else if (mode === TSMode.AddPolyline) {
      // create new node
      let newNode: INode = { id: GenerateID(), position: mousePos, offset: { x: 0, y: 0 } };
      let nodes: INode[] = activeNodes || [];
      if (nodes.length > 2 && EqualNode(newNode, nodes[0])) {
        // check beam openings
        let newBeam: IBeam = CreateBeamFromNodes(nodes, true);
        task.AddBeam(newBeam);
        setActiveNodes(null);
      } else if (NodeInNodes(newNode, nodes)) {
        message.warn('A polyline should not have duplicated node except the end node.');
        return;
      } else {
        setActiveNodes([...nodes, newNode]);
      }
    }
    // move mode
    else if (mode === TSMode.MoveStart) {
      // set the x,y of move
      let newMove: IMove = { x1: mousePos.x, y1: mousePos.y, x2: 0, y2: 0 };
      setMove(newMove);
      setMode(TSMode.MoveEnd);
    } else if (mode === TSMode.MoveEnd) {
      // move selected
      task.EndMove();
      setMove({ x1: 0, y1: 0, x2: 0, y2: 0 });
      setMode(TSMode.Select);
    }
  }

  function onMouseDown(e: KonvaEventObject<MouseEvent>) {
    if (mode === TSMode.Select) {
      setSelection({ ...selection, selecting: true, x1: e.evt.offsetX, y1: e.evt.offsetY });
    }
  }

  function onMouseMove(e: KonvaEventObject<MouseEvent>) {
    let x = Math.round(e.evt.offsetX);
    let y = Math.round(e.evt.offsetY);
    if (isSnap) {
      x = Math.round(x / drawingData.gridSize) * drawingData.gridSize;
      y = Math.round(y / drawingData.gridSize) * drawingData.gridSize;
    }
    setMousePos({ x, y });

    // check selection
    if (mode === TSMode.Select) {
      if (!selection.selecting) return;
      let newSelection: ISelection = { ...selection, activate: true, x2: e.evt.offsetX, y2: e.evt.offsetY };
      setSelection(newSelection);
      // check which columns are selected
      if (e.evt.ctrlKey) {
        task.Select(newSelection, 'remove');
      } else if (e.evt.shiftKey) {
        task.Select(newSelection, 'add');
      } else {
        task.Select(newSelection, 'normal');
      }
    }

    // check move
    else if (mode === TSMode.MoveEnd) {
      // set the x,y of move
      let newMove: IMove = { ...move, x2: mousePos.x, y2: mousePos.y };
      setMove(newMove);
      let offset: IVector = { x: newMove.x2 - newMove.x1, y: newMove.y2 - newMove.y1 };
      task.StartMove(offset);
    }
  }

  function onMouseUp(e: KonvaEventObject<MouseEvent>) {
    if (mode === TSMode.Select) {
      setSelection({ ...selection, selecting: false, activate: false });
    }
  }
};

export default TSWorkspace;
