import React from 'react';
import { TSMode, INode } from './Interfaces';
import { IDrawingData } from '../../store/state';
import { ITask } from '../../store/task';
import { Layer, Line, Group, Text } from 'react-konva';
import { getDistAndAngleFrom2Points, GetSpansOfColumns, GetMaximumSpan, GetSpanOfColumnsAndWalls, GetSpansOfWalls } from './helper';

export interface SpanLayerProps {
  drawingData: IDrawingData;
  mode: TSMode;
  task: ITask;
}

const TSSpanLayer: React.FC<SpanLayerProps> = ({ drawingData, mode, task }) => {
  let segColumns: INode[] = [];
  for (let beam of drawingData.beams) {
    for (let seg of beam.segments) {
      if (seg.columns) segColumns = [...segColumns, ...seg.columns];
    }
  }

  const allColumns = [...drawingData.columns, ...segColumns];

  GetSpansOfColumns(allColumns);
  GetSpansOfWalls(drawingData.beams);
  GetSpanOfColumnsAndWalls(allColumns, drawingData.beams);

  const scale = drawingData.gridUnit / drawingData.gridSize;

  const maxSpans = GetMaximumSpan(allColumns, drawingData.beams);

  if (!maxSpans) return null;

  return (
    <Layer name='spanlayer'>
      <Group>
        {maxSpans.map((maxSpan, index) => {
          const posA = maxSpan.spanStart;
          const posB = maxSpan.spanEnd;
          let { angle } = getDistAndAngleFrom2Points(posA, posB);
          return (
            <Group key={index}>
              <Text
                text={(maxSpan.span * scale).toFixed(2) + ' mm'}
                fill='#d73a49'
                x={(posA.x + posB.x) / 2}
                y={(posA.y + posB.y) / 2}
                fontSize={16}
                rotation={-angle}
                align='center'
                verticalAlign='bottom'
                padding={5}
              />
              <Line dash={[5, 5]} points={[posA.x, posA.y, posB.x, posB.y]} strokeWidth={2} stroke='steelblue' />
            </Group>
          );
        })}
      </Group>
    </Layer>
  );
};

function areEqual(prevProps: SpanLayerProps, nextProps: SpanLayerProps) {
  return prevProps.drawingData === nextProps.drawingData;
}

export default React.memo(TSSpanLayer, areEqual);
