import React from 'react';
import { Layer, Line } from 'react-konva';
import { GridLayerProps } from './Interfaces';

const TSGridLayer: React.FC<GridLayerProps> = ({ width, height, gridsize }) => {
  // compose points for line
  var lines: number[][] = [];

  for (let i = 1; i < width / gridsize; i++) {
    lines.push([gridsize * i, 0, gridsize * i, height]);
  }

  for (let i = 1; i < height / gridsize; i++) {
    lines.push([0, gridsize * i, width, gridsize * i]);
  }

  const dashLength = gridsize / 10;

  return (
    <Layer name='gridlayer'>
      {lines.map((line, index) => (
        <Line key={index} points={line} dash={[dashLength, dashLength]} opacity={0.3} stroke='black' strokeWidth={1} />
      ))}
    </Layer>
  );
};

export default TSGridLayer;
