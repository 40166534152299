/*
  The version file defines all versions
*/

export interface IVersion {
  number: string;
  date: string;
  descriptions: string[];
}

export const versions: IVersion[] = [
  {
    number: '0.1.2',
    date: '2020-08-12',
    descriptions: ['- Added Maximum span calculation.'],
  },
  {
    number: '0.1.1',
    date: '2020-05-26',
    descriptions: ['- Added Redo/Undo.', '- Added ColumnRow option for line.', '- Updated UI/UX', '- Optimized some functions.'],
  },
  {
    number: '0.1.0',
    date: '2020-03-25',
    descriptions: ['- Initial version.'],
  },
];
