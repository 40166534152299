/*
  The reducers handels all granular actions.
  It changes the state and returns a new one.
*/

import { initialState, IState, IDrawing, IDrawingData, defaultDrawingData } from './state';
import {
  SetBeamOpenings,
  NodeInSelection,
  LineInSelection,
  MoveBeamSegments,
  DeleteBeamSegments,
  CalcColumnsFromColumnRowSegment,
} from '../Components/TSCanvas/helper';

export const reducer: React.Reducer<IState, any> = (state: IState = initialState, action: any) => {
  var newColumns;
  var newBeams;
  var drawingData: IDrawingData;
  var mode: string;

  switch (action.type) {
    // get project
    case 'GET_PROJECT_PENDING':
      return { ...state, isProjectLoading: true };
    case 'GET_PROJECT_FULFILLED':
      var drawings: IDrawing[] = action.payload.drawings;

      var drawing = drawings[0];
      if (drawing.serializedData) {
        const { gridSize, gridUnit, columns, beams } = JSON.parse(drawing.serializedData);
        drawingData = { gridSize, gridUnit, columns, beams };
        return {
          ...state,
          isProjectLoading: false,
          project: action.payload,
          drawing: drawing,
          drawingData,
          lastSavedData: drawingData,
          dataQueue: [drawingData],
        };
      }
      return {
        ...state,
        isProjectLoading: false,
        project: action.payload,
        drawing: drawing,
      };

    case 'GET_PROJECT_ERROR':
      return {
        ...state,
        isProjectLoading: false,
      };

    // update project
    case 'UPDATE_PROJECT_FULFILLED':
      return {
        ...state,
        project: action.payload,
      };
    // create drawing
    case 'CREATE_DRAWING_FULFILLED':
      if (!state.project) return state;
      return {
        ...state,
        project: { ...state.project, drawings: [...state.project.drawings, action.payload] },
        drawing: action.payload,
        lastSavedData: state.drawingData,
      };

    // update drawing
    case 'UPDATE_DRAWING_FULFILLED':
      if (!state.project) return state;
      return {
        ...state,
        project: { ...state.project, drawings: state.project.drawings.map((d) => (d.id === action.payload.id ? action.payload : d)) },
        drawing: action.payload,
        lastSavedData: state.drawingData,
      };
    // delete drawing
    case 'DELETE_DRAWING_FULFILLED':
      if (!state.project) return state;
      return {
        ...state,
        project: { ...state.project, drawings: state.project.drawings.filter((d) => d.id !== action.payload) },
        drawing: state.project.drawings[0],
      };
    // select drawing
    case 'SET_ACTIVE_DRAWING':
      if (action.payload.serializedData) {
        const { gridSize, gridUnit, columns, beams } = JSON.parse(action.payload.serializedData);
        drawingData = { gridSize, gridUnit, columns, beams };
        return {
          ...state,
          drawing: action.payload,
          drawingData,
          lastSavedData: drawingData,
          dataQueue: [drawingData],
        };
      }
      return {
        ...state,
        drawing: action.payload,
        drawingData: defaultDrawingData,
        lastSavedData: defaultDrawingData,
        dataQueue: [defaultDrawingData],
      };

    // add beam
    case 'CREATE_BEAM':
      newBeams = [...state.drawingData.beams, action.payload];
      if (action.payload.isClosed) {
        newBeams = SetBeamOpenings(newBeams);
      }
      drawingData = {
        ...state.drawingData,
        beams: newBeams,
      };
      return AddToUndo(state, drawingData);
    // add beam
    case 'CREATE_COLUMN':
      drawingData = {
        ...state.drawingData,
        columns: [...state.drawingData.columns, action.payload],
      };
      return AddToUndo(state, drawingData);
    case 'SET_GRID_UNIT':
      drawingData = {
        ...state.drawingData,
        gridUnit: action.payload,
      };
      return AddToUndo(state, drawingData);
    case 'SET_SUPPORT_STATE':
      drawingData = {
        ...state.drawingData,
        beams: state.drawingData.beams.map((b) => {
          return {
            ...b,
            segments: b.segments.map((s) => {
              if (s.isSelected) {
                var newSeg = { ...s, isSupported: action.payload };
                if (newSeg.hasOwnProperty('columns')) delete newSeg.columns;
                if (newSeg.hasOwnProperty('columnsCount')) delete newSeg.columnsCount;
                return newSeg;
              }
              return s;
            }),
          };
        }),
      };
      return AddToUndo(state, drawingData);

    case 'SET_COLUMN_NUMBER':
      drawingData = {
        ...state.drawingData,
        beams: state.drawingData.beams.map((b) => {
          return {
            ...b,
            segments: b.segments.map((s) => {
              if (s.isSelected) {
                var newSeg = { ...s, columnsCount: action.payload };
                CalcColumnsFromColumnRowSegment(newSeg);
                if (action.payload && newSeg.hasOwnProperty('isSupported')) delete newSeg.isSupported;
                return newSeg;
              }
              return s;
            }),
          };
        }),
      };
      return AddToUndo(state, drawingData);

    case 'UPDATE_BEAM':
      let beam = action.payload;
      newBeams = state.drawingData.beams.map((b) => (b.id === beam.id ? beam : b));
      drawingData = {
        ...state.drawingData,
        beams: newBeams,
      };
      return AddToUndo(state, drawingData);
    case 'UPDATE_SEGMENT':
      let segment = action.payload;
      newBeams = state.drawingData.beams.map((beam) => {
        return {
          ...beam,
          segments: beam.segments.map((seg) => (seg.id === segment.id ? segment : seg)),
        };
      });
      drawingData = {
        ...state.drawingData,
        beams: newBeams,
      };
      return AddToUndo(state, drawingData);

    case 'DELETE_SELECTED':
      // delete columns
      newColumns = state.drawingData.columns.filter((c) => !c.isSelected);
      // delete segments
      newBeams = DeleteBeamSegments(state.drawingData.beams);

      drawingData = {
        ...state.drawingData,
        columns: newColumns,
        beams: newBeams,
      };
      return AddToUndo(state, drawingData);

    case 'SELECT_COLUMN':
      let node = action.payload.node;
      mode = action.payload.mode;
      if (mode === 'add') {
        newColumns = state.drawingData.columns.map((n) => {
          if (n.id !== node.id) return n;
          return { ...node, isSelected: true };
        });
      } else if (mode === 'remove') {
        newColumns = state.drawingData.columns.map((n) => {
          if (n.id !== node.id) return n;
          return { ...node, isSelected: false };
        });
      } else {
        newColumns = state.drawingData.columns.map((n) => {
          if (n.id !== node.id) return { ...n, isSelected: false };
          return { ...node, isSelected: true };
        });
      }
      drawingData = {
        ...state.drawingData,
        columns: newColumns,
      };
      return {
        ...state,
        drawingData,
      };

    case 'SELECT':
      let selection = action.payload.selection;
      mode = action.payload.mode;
      if (mode === 'add') {
        newColumns = state.drawingData.columns.map((node) => {
          if (NodeInSelection(node, selection)) return { ...node, isSelected: true };
          return node;
        });
        newBeams = state.drawingData.beams.map((beam) => {
          return {
            ...beam,
            segments: beam.segments.map((seg) => ({
              ...seg,
              isSelected: LineInSelection(seg.start, seg.end, selection) ? true : seg.isSelected,
              start: { ...seg.start, isSelected: false },
              end: { ...seg.end, isSelected: false },
            })),
          };
        });
      } else if (mode === 'remove') {
        newColumns = state.drawingData.columns.map((node) => {
          if (NodeInSelection(node, selection)) return { ...node, isSelected: false };
          return node;
        });
        newBeams = state.drawingData.beams.map((beam) => {
          return {
            ...beam,
            segments: beam.segments.map((seg) => ({
              ...seg,
              isSelected: LineInSelection(seg.start, seg.end, selection) ? false : seg.isSelected,
              start: { ...seg.start, isSelected: false },
              end: { ...seg.end, isSelected: false },
            })),
          };
        });
      } else {
        newColumns = state.drawingData.columns.map((node) => {
          if (NodeInSelection(node, selection)) return { ...node, isSelected: true };
          return { ...node, isSelected: false };
        });
        newBeams = state.drawingData.beams.map((beam) => {
          return {
            ...beam,
            segments: beam.segments.map((seg) => ({
              ...seg,
              isSelected: LineInSelection(seg.start, seg.end, selection),
              start: { ...seg.start, isSelected: false },
              end: { ...seg.end, isSelected: false },
            })),
          };
        });
      }
      drawingData = {
        ...state.drawingData,
        columns: newColumns,
        beams: newBeams,
      };
      return {
        ...state,
        drawingData,
      };

    case 'UNSELECT_ALL':
      // clear selection and move
      newColumns = state.drawingData.columns.map((c) => ({ ...c, isSelected: false, offset: { x: 0, y: 0 } }));
      newBeams = state.drawingData.beams.map((b) => {
        return {
          ...b,
          segments: b.segments.map((s) => ({
            ...s,
            isSelected: false,
            start: { ...s.start, isSelected: false, offset: { x: 0, y: 0 } },
            end: { ...s.end, isSelected: false, offset: { x: 0, y: 0 } },
          })),
        };
      });
      drawingData = {
        ...state.drawingData,
        columns: newColumns,
        beams: newBeams,
      };
      return {
        ...state,
        drawingData,
      };

    case 'UNSELECT_ALL_NODES':
      newBeams = state.drawingData.beams.map((b) => {
        return {
          ...b,
          segments: b.segments.map((s) => ({
            ...s,
            isSelected: false,
            start: { ...s.start, isSelected: false, offset: { x: 0, y: 0 } },
            end: { ...s.end, isSelected: false, offset: { x: 0, y: 0 } },
          })),
        };
      });
      drawingData = {
        ...state.drawingData,
        beams: newBeams,
      };
      return {
        ...state,
        drawingData,
      };
    case 'START_MOVE':
      var offset = action.payload;
      newColumns = state.drawingData.columns.map((c) => (c.isSelected ? { ...c, offset } : c));
      // move segments
      newBeams = state.drawingData.beams.map((b) => {
        let segmentOffset = MoveBeamSegments(b, offset);
        return {
          ...b,
          segments: b.segments.map((s) => {
            let newStart = segmentOffset.offsetStart[s.id] ? { ...s.start, offset: segmentOffset.offsetStart[s.id] } : s.start;
            let newEnd = segmentOffset.offsetEnd[s.id] ? { ...s.end, offset: segmentOffset.offsetEnd[s.id] } : s.end;
            return { ...s, start: newStart, end: newEnd };
          }),
        };
      });
      drawingData = {
        ...state.drawingData,
        columns: newColumns,
        beams: newBeams,
      };
      return {
        ...state,
        drawingData,
      };
    case 'END_MOVE':
      newColumns = state.drawingData.columns.map((c) => ({
        ...c,
        position: { x: c.position.x + c.offset.x, y: c.position.y + c.offset.y },
        offset: { x: 0, y: 0 },
      }));
      newBeams = state.drawingData.beams.map((b) => ({
        ...b,
        segments: b.segments.map((s) => ({
          ...s,
          start: {
            ...s.start,
            position: { x: s.start.position.x + s.start.offset.x, y: s.start.position.y + s.start.offset.y },
            offset: { x: 0, y: 0 },
          },
          end: {
            ...s.end,
            position: { x: s.end.position.x + s.end.offset.x, y: s.end.position.y + s.end.offset.y },
            offset: { x: 0, y: 0 },
          },
        })),
      }));
      newBeams = SetBeamOpenings(newBeams);
      drawingData = {
        ...state.drawingData,
        columns: newColumns,
        beams: newBeams,
      };
      return AddToUndo(state, drawingData);

    case 'UNDO':
      if (state.undoIndex - 1 >= 0) {
        return {
          ...state,
          drawingData: state.dataQueue[state.undoIndex - 1],
          undoIndex: state.undoIndex - 1,
        };
      }
      return state;

    case 'REDO':
      if (state.undoIndex + 1 <= state.dataQueue.length - 1) {
        return {
          ...state,
          drawingData: state.dataQueue[state.undoIndex + 1],
          undoIndex: state.undoIndex + 1,
        };
      }
      return state;

    default:
      return state;
  }
};

function AddToUndo(state: IState, newData: IDrawingData): IState {
  if (state.dataQueue.length === 0) {
    return {
      ...state,
      drawingData: newData,
      dataQueue: [newData],
      undoIndex: 0,
    };
  }
  if (JSON.stringify(newData) !== JSON.stringify(state.dataQueue[state.dataQueue.length - 1])) {
    let queue = [...state.dataQueue];
    if (queue.length > state.undoIndex + 1) queue.splice(state.undoIndex + 1);
    if (queue.length > 100) queue.shift();
    queue.push(newData);
    return {
      ...state,
      drawingData: newData,
      dataQueue: queue,
      undoIndex: queue.length - 1,
    };
  } else return state;
}
