/*
  api that relates to this module
  that connects to the backend
*/

import axios from 'axios';

const url = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:5100/' : 'https://ts3-api-internal.d2p.ch/';
axios.defaults.baseURL = url;

export interface INewProjectPayload {
  name: string;
  description?: string;
  email: string;
}

export interface IEditProjectPayload {
  name?: string;
  description?: string;
}

export interface INewDrawingPayload {
  projectShortId: string;
  name: string;
  serializedData: string;
  currentDrawingID: number;
}

export interface IEditDrawingPayload {
  name?: string;
  serializedData?: string;
  result?: string;
}

export const API = {
  //  ====================================GET REQUEST====================================
  getProject(projectTextID: string) {
    return axios.get(`/projects/${projectTextID}`);
  },

  getProjects(email: string) {
    return axios.get(`/projects`, { params: { email } });
  },

  //  ====================================POST REQUEST====================================
  createProject(projectPayload: INewProjectPayload) {
    return axios.post(`/projects`, projectPayload);
  },

  createDrawing(drawingPayload: INewDrawingPayload) {
    return axios.post(`/drawings`, drawingPayload);
  },

  calculateDrawing(drawingID: number, serializedDrawing: XMLDocument) {
    const xmlContent = new XMLSerializer().serializeToString(serializedDrawing);
    return axios.post(`/drawings/${drawingID}/calculate`, { xmlContent });
  },

  //  ====================================PUT REQUEST====================================
  updateProject(projectTextID: string, projectPayload: IEditProjectPayload) {
    return axios.put(`/projects/${projectTextID}`, projectPayload);
  },

  updateDrawing(drawingID: number, drawingPayload: IEditDrawingPayload) {
    return axios.put(`/drawings/${drawingID}`, drawingPayload);
  },

  //  ====================================DELETE REQUEST====================================
  deleteProject(projectTextID: string) {
    return axios.delete(`/projects/${projectTextID}`);
  },

  deleteDrawing(drawingID: number) {
    return axios.delete(`/drawings/${drawingID}`);
  },
};
