import React from 'react';
import './App.css';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import NotFound from './NotFound';
import { path } from './path';
import { Dashboard } from './Components/Dashboard';
import { Project } from './Components/Project';
import { ContextProvider } from './store';

function App() {
  return (
    <ContextProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path={path.dashboard} component={Dashboard} />
          <Route exact path={path.project} component={Project} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </ContextProvider>
  );
}

export default App;
