import React, { useState } from 'react';
import './Drawing.css';
import { t } from '../../localization';
import { Input, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import TSCanvas from '../TSCanvas';
import { IProject, IDrawing, IDrawingData } from '../../store/state';
import { distanceString } from '../../localization/timing';
import { ITask } from '../../store/task';

interface DrawingProps {
  project: IProject;
  drawing: IDrawing;
  drawingData: IDrawingData;
  unSaved: boolean;
  task: ITask;
}

export function Drawing({ project, drawing, drawingData, unSaved, task }: DrawingProps) {
  return (
    <section className='project-container'>
      <section className='meta-container'>
        <section className='infos' style={{ marginBottom: '30px' }}>
          <Info title={t('PROJECT_ID')} editable={false} value={project.shortId} setValue={null} />
          <Info title={t('PROJECT_NAME')} value={project.name} setValue={(val: string) => updateProject({ ...project, name: val })} />
          <Info
            title={t('PROJECT_DESCRIPTION')}
            value={project.description}
            setValue={(val: string) => updateProject({ ...project, description: val })}
          />
          <Info title={t('CREATOR')} editable={false} value={project.creator.email} setValue={null} />

          <Info title={t('DRAWING_NAME')} value={drawing.name} setValue={(val: string) => updateDrawing({ ...drawing, name: val })} />
          <Info
            title={t('DRAWING_DATE')}
            editable={false}
            value={distanceString(drawing.createdAt.toString())}
            setValue={(val: string) => updateDrawing({ ...drawing, createdAt: new Date(val) })}
          />
        </section>
      </section>
      <TSCanvas width={1400} height={700} drawing={drawing} drawingData={drawingData} unSaved={unSaved} task={task} />
    </section>
  );

  async function updateProject(prj: IProject) {
    let response = await task.UpdateProject(prj.shortId, { name: prj.name, description: prj.description });
    if (response.success) {
      message.success(t('INFO_UPDATED'));
    }
  }

  async function updateDrawing(drw: IDrawing) {
    let response = await task.UpdateDrawing(drw.id, { name: drw.name, serializedData: drw.serializedData });
    if (response.success) {
      message.success(t('INFO_UPDATED'));
    }
  }
}

interface InfoProps {
  title: string;
  value: string;
  setValue: any;
  editable?: boolean;
}

function Info({ title, value, setValue, editable = true }: InfoProps) {
  const [edit, setEdit] = useState<boolean>(false);
  const [val, setVal] = useState(value);

  function submitChange() {
    setValue(val);
    setEdit(false);
  }

  return (
    <section className='info-container'>
      <div className='info-title'>
        <span>{title}</span>
        {editable && <EditOutlined style={{ cursor: 'pointer', marginLeft: '3px' }} onClick={() => setEdit(true)} />}
      </div>
      <div className='info-value'>
        {edit ? <Input size='small' value={val} onChange={(e) => setVal(e.target.value)} onPressEnter={submitChange}></Input> : <span>{value}</span>}
      </div>
    </section>
  );
}
