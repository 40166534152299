import React from 'react';
import { Button } from 'antd';
import './App.css';
import { t } from './localization';

export default function NotFound() {
  return (
    <section className='not-found-container'>
      <label className='not-found-icon'>404</label>
      <p className='not-found-title'>{t('PAGE_NOT_FOUND')}</p>
      <p className='not-found-description'>{t('PAGE_NOT_FOUND_DESCRIPTION')}</p>
      <Button href='/' className='not-found-button'>
        {t('HOME')}
      </Button>
    </section>
  );
}
