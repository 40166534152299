import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { t } from '../../localization';
import { FileTextOutlined, HomeOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { Layout, Menu, Typography, Popconfirm, message, Modal } from 'antd';
import { IProject, IDrawing, IDrawingData } from '../../store/state';
import { ITask } from '../../store/task';

const { Sider } = Layout;

export interface SiderbarProps {
  project: IProject;
  task: ITask;
  drawing: IDrawing;
  drawingData: IDrawingData;
  unSaved: boolean;
}

export function Sidebar({ project, task, drawing, drawingData, unSaved }: SiderbarProps) {
  const [collapse, setCollapse] = useState<boolean>(false);
  return (
    <Sider collapsible collapsed={collapse} onCollapse={(collapsed) => setCollapse(collapsed)}>
      <Menu theme='dark' mode='inline' selectedKeys={drawing ? [`${drawing.id}`] : []}>
        <Menu.Item key='home'>
          <Link to='/'>
            <Typography.Title level={4} style={{ color: 'white', marginTop: '6px' }}>
              <HomeOutlined />
              <span>TS 3</span>
            </Typography.Title>
          </Link>
        </Menu.Item>
        {project.drawings.map((drawing) => (
          <Menu.Item key={drawing.id} onClick={() => selectDrawing(drawing)}>
            <FileTextOutlined />
            <span>{drawing.name}</span>
            {project.drawings.length > 1 && (
              <Popconfirm title={t('DELETE_DRAWING_CONFIRM')} onConfirm={() => deleteDrawing(drawing)} okText={t('YES')} cancelText={t('NO')}>
                <DeleteOutlined style={{ marginLeft: '5px' }} />
              </Popconfirm>
            )}
          </Menu.Item>
        ))}
        <Menu.Divider style={{ opacity: '0.3' }} />
        <Menu.Item key='add' onClick={() => copyDrawing(drawing)}>
          <CopyOutlined />
          <span>{t('COPY_DRAWING')}</span>
        </Menu.Item>
      </Menu>
    </Sider>
  );

  function SaveConfirm(newDrawing: IDrawing) {
    Modal.confirm({
      title: 'Drawing Unsaved',
      icon: null,
      content: 'The drawing has unsaved content. Do you want to save it?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => onConfirm(true, newDrawing),
      onCancel: () => onConfirm(false, newDrawing),
    });
  }

  async function onConfirm(save: boolean, newDrawing: IDrawing) {
    if (save) await saveCanvas();
    task.SelectDrawing(newDrawing);
  }

  function selectDrawing(newDrawing: IDrawing) {
    if (unSaved) SaveConfirm(newDrawing);
    else task.SelectDrawing(newDrawing);
  }

  async function copyDrawing(drawing: IDrawing) {
    await saveCanvas();
    await task.CreateDrawing({ projectShortId: project.shortId, name: t('UNTITLED'), serializedData: '', currentDrawingID: drawing.id });
  }

  async function saveCanvas() {
    let response = await task.UpdateDrawing(drawing.id, { serializedData: JSON.stringify(drawingData) });
    if (response.success) {
      message.success(t('DRAWING_SAVED'));
    }
  }

  function deleteDrawing(drawing: IDrawing) {
    task.DeleteDrawing(drawing.id);
  }
}
