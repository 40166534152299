import english from './locales/english';
import german from './locales/german';
import french from './locales/french';

const locales: { [key: string]: { [key: string]: string } } = {
  English: english,
  German: german,
  French: french
};

const dict: { [key: string]: string } = {
  en: 'English',
  de: 'German',
  fr: 'French'
};

function userLocale() {
  // return user language: English, German, French, Italien
  let language = window.localStorage.getItem('locale');
  if (!language) {
    return null;
  }
  return language;
}

function fallbackLocale() {
  // return browser lanague
  const browserLan: string = window.navigator.language;
  return dict[browserLan.substring(0, 2)] || 'English';
}

// the localization function
export function t(key: string): string {
  const localeLanguage = userLocale() || fallbackLocale();
  const locale: { [key: string]: string } = locales[localeLanguage];
  if (locale.hasOwnProperty(key)) return locale[key];
  if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && key !== '') console.warn(`Translation Missing: ${localeLanguage}: ${key}`);
  return (english as { [key: string]: string })[key] || key;
}
