import React from 'react';
import { TSMode, INode, ISegment } from './Interfaces';
import { Group, Circle } from 'react-konva';
import { KonvaEventObject } from 'konva/types/Node';
import { ITask } from '../../store/task';

export interface NodeProps {
  node: INode;
  segment: ISegment;
  mode: TSMode;
  task: ITask;
}

export const TSNode: React.FC<NodeProps> = ({ node, segment, task, mode }) => {
  let x = node.position.x + node.offset.x;
  let y = node.position.y + node.offset.y;

  if (!segment.isSelected && !node.isSelected) return null;

  return (
    <Group onClick={onClick}>
      <Circle
        x={x}
        y={y}
        radius={node.isSelected ? 15 : 8}
        stroke='black'
        strokeWidth={1}
        opacity={0.8}
        fill={node.isSelected ? 'yellow' : '#d2d2d2'}
      />
    </Group>
  );

  function onClick(e: KonvaEventObject<MouseEvent>) {
    if (mode !== TSMode.Select) return;

    var newSeg: ISegment = {
      ...segment,
      isSelected: false,
      start: { ...segment.start, isSelected: false },
      end: { ...segment.end, isSelected: false },
    };
    if (node.id === newSeg.start.id) newSeg.start.isSelected = true;
    if (node.id === newSeg.end.id) newSeg.end.isSelected = true;
    task.UpdateSegment(newSeg);
  }
};
