import React from 'react';
import { Layer } from 'react-konva';
import TSBeam from './TSBeam';
import { TSMode } from './Interfaces';
import { IDrawingData } from '../../store/state';
import { ITask } from '../../store/task';
import { TSColumn } from './TSColumn';

export interface CompLayerProps {
  drawingData: IDrawingData;
  mode: TSMode;
  task: ITask;
}

const TSCompLayer: React.FC<CompLayerProps> = ({ drawingData, mode, task }) => {
  return (
    <Layer name='complayer'>
      {drawingData.beams.map((beam) => (
        <TSBeam key={beam.id} beam={beam} task={task} mode={mode} />
      ))}
      {drawingData.columns.map((column) => (
        <TSColumn key={column.id} column={column} task={task} mode={mode} />
      ))}
    </Layer>
  );
};

export default TSCompLayer;
