import React from 'react';
import { IVector, IBeam, TSMode } from './Interfaces';
import { Group, Line } from 'react-konva';
import { TSSegment } from './TSSegment';
import { ITask } from '../../store/task';

export interface BeamProps {
  beam: IBeam;
  task: ITask;
  mode: TSMode;
}

const TSBeam: React.FC<BeamProps> = ({ beam, task, mode }) => {
  return (
    <Group>
      <Line points={getBeamPoints()} fill={beam.isOpening ? '#e8e8e8' : 'grey'} closed={beam.isClosed} />
      {beam.segments.map((segment) => (
        <TSSegment key={segment.id} segment={segment} beam={beam} task={task} mode={mode} />
      ))}
    </Group>
  );

  function getBeamPoints(): number[] {
    var points: IVector[] = [];
    if (!beam.isClosed) {
      var startPt = beam.segments[0].start;
      points.push({ x: startPt.position.x + startPt.offset.x, y: startPt.position.y + startPt.offset.y });
    }
    beam.segments.forEach((seg) => points.push({ x: seg.end.position.x + seg.end.offset.x, y: seg.end.position.y + seg.end.offset.y }));
    return points.flatMap((v) => [v.x, v.y]);
  }
};

export default TSBeam;
