import React from 'react';
import { Layer, Circle, Rect, Group, Line } from 'react-konva';
import { TSMode, IVector, ISelection, IMove, INode } from './Interfaces';

export interface GhostLayerProps {
  mode: TSMode;
  mousePos: IVector;
  selection: ISelection;
  activeNodes: INode[] | null;
  move: IMove;
}

// the ghost layer has a pointer showing where the regulated position are
export const TSGhostLayer: React.FC<GhostLayerProps> = ({ mousePos, mode, selection, activeNodes, move }) => {
  return (
    <Layer name='ghostlayer'>
      {mode === TSMode.Select && selection.activate && (
        <Rect
          stroke='black'
          strokeWidth={1}
          fill='#fff8b9'
          opacity={0.3}
          x={selection.x1}
          y={selection.y1}
          width={selection.x2 - selection.x1}
          height={selection.y2 - selection.y1}
        />
      )}
      {mode === TSMode.AddColumn && ColumnGhost()}
      {mode === TSMode.AddPolyline && BeamGhost()}
      {mode === TSMode.MoveStart && MoveStartGhost()}
      {mode === TSMode.MoveEnd && MoveEndGhost()}
      {mode === TSMode.AddLineStart && LineStartGhost()}
      {mode === TSMode.AddLineEnd && LineEndGhost()}
      {mode === TSMode.AddRectStart && RectStartGhost()}
      {mode === TSMode.AddRectEnd && RectEndGhost()}
    </Layer>
  );

  function ColumnGhost() {
    return <Circle radius={8} x={mousePos.x} y={mousePos.y} stroke='red' strokeWidth={2} opacity={0.6} />;
  }

  function MoveStartGhost() {
    return <Circle radius={5} x={mousePos.x} y={mousePos.y} fill='red' opacity={0.6} />;
  }

  function LineStartGhost() {
    return (
      <Group>
        <Circle radius={8} x={mousePos.x} y={mousePos.y} stroke='red' strokeWidth={2} opacity={0.6} />
      </Group>
    );
  }

  function RectStartGhost() {
    return (
      <Group>
        <Circle radius={8} x={mousePos.x} y={mousePos.y} stroke='red' strokeWidth={2} opacity={0.6} />
      </Group>
    );
  }

  function LineEndGhost() {
    let dashedPoints: number[] = [];
    if (!activeNodes) return null;
    dashedPoints = [activeNodes[0].position.x, activeNodes[0].position.y, mousePos.x, mousePos.y];

    return (
      <Group>
        <Line points={dashedPoints} stroke='black' strokeWidth={2} dash={[5]} closed />
        <Circle radius={8} x={mousePos.x} y={mousePos.y} stroke='red' strokeWidth={2} opacity={0.6} />
      </Group>
    );
  }

  function RectEndGhost() {
    let dashedPoints: number[] = [];
    if (!activeNodes) return null;
    let firstNode = activeNodes[0];
    dashedPoints = [
      firstNode.position.x,
      firstNode.position.y,
      mousePos.x,
      firstNode.position.y,
      mousePos.x,
      mousePos.y,
      firstNode.position.x,
      mousePos.y,
    ];

    return (
      <Group>
        <Line points={dashedPoints} stroke='black' strokeWidth={2} dash={[5]} closed />
        <Circle radius={8} x={mousePos.x} y={mousePos.y} stroke='red' strokeWidth={2} opacity={0.6} />
      </Group>
    );
  }

  function MoveEndGhost() {
    return (
      <Group>
        <Line points={[move.x1, move.y1, mousePos.x, mousePos.y]} stroke='red' strokeWidth={3} dash={[5]} opacity={0.6} />
        <Circle radius={5} x={move.x1} y={move.y1} fill='red' opacity={0.6} />
        <Circle radius={5} x={mousePos.x} y={mousePos.y} fill='red' opacity={0.6} />
      </Group>
    );
  }

  function BeamGhost() {
    if (!activeNodes)
      return (
        <Group>
          <Circle radius={8} x={mousePos.x} y={mousePos.y} stroke='red' strokeWidth={2} opacity={0.6} />
        </Group>
      );

    let length = activeNodes.length;
    let solidPoints: number[] = [];
    let dashedPoints: number[] = [];

    if (length > 0) {
      let lastNode = activeNodes[length - 1];
      dashedPoints = [lastNode.position.x, lastNode.position.y, mousePos.x, mousePos.y];
    }

    if (length > 1) {
      for (let node of activeNodes) {
        solidPoints.push(node.position.x);
        solidPoints.push(node.position.y);
      }
    }

    return (
      <Group>
        {length > 0 && <Line points={dashedPoints} stroke='black' strokeWidth={2} dash={[5]} />}
        {length > 1 && <Line points={solidPoints} stroke='black' strokeWidth={2} />}
        <Circle radius={8} x={mousePos.x} y={mousePos.y} stroke='red' strokeWidth={2} opacity={0.6} />
      </Group>
    );
  }
};
